import PropTypes from "prop-types";
import IntlMessages from "components/util/IntlMessages";

// This componeent will receive the id for the texts translation and the input if is needed the red-line above the title
const GenericTitle = ({ textId, redLine, textIDValues }) => {
    return (
        <div className="generic-title">
            <IntlMessages id={textId} values={textIDValues} />
            {redLine && <div className="red-line" />}
        </div>
    );
};


GenericTitle.defaultProps = {
    title: 'label.loading',
    redLine: false,
    textIDValues: null,
}


GenericTitle.propTypes = {
    textId: PropTypes.string.isRequired,
    redLine: PropTypes.bool.isRequired
};

export default GenericTitle;
