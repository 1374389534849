/**
 * @author Miguel Cervera <miguel.cervera@caixamagica.pt>, Daniel Bastos <daniel.bastos@caixamagica.pt>
 *
 * @description Component Context - Individual Module Component
 * @article about PageComponent https://medium.com/@learnreact/container-components-c0e67432e005#.1a9j3w1jl
 *
 * @version 20200918
 * @since 20200918 Adding Modules
 *
 */

import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import IntlMessages from 'components/util/IntlMessages';

//Component
const Loading = ({ size, className, useTopLoading, label, color, ...rest }) => {
    NProgress.configure({ showSpinner: false, trickleSpeed: 30 });

    if (!!useTopLoading && !NProgress.isStarted()) NProgress.start();

    useEffect(() => {
        return () => {
            if (!!useTopLoading) NProgress.done();
        };
    }, [useTopLoading]);

    return (
        <div className={`loading-component${className ? ' ' + className : ''}`} {...rest}>
            <CircularProgress size={!!size ? size : 30} color={color} />
            {label && (
                <label>
                    <IntlMessages id={label} />
                </label>
            )}
        </div>
    );
};

export default Loading;
