import { useEffect } from 'react';
import Loading from 'components/Loading';
import { goToAuthADFS } from 'services/user';

const LogoutADFS = () => {

    useEffect(() => {
        goToAuthADFS();
    }, []); //eslint-disable-line


    return (
        <div className={'app-main-login'}>
            <Loading label={'label.pleaseWait'} />
        </div>
    );
};

export default (LogoutADFS);
