import { memo } from 'react';
import PropTypes from 'prop-types';

//Material UI
import { ReactComponent as OpportunitiesIcon } from 'assets/images/icons/svg/opportunities.svg';
import useTheme from '@mui/material/styles/useTheme';
import IntlMessages from 'components/util/IntlMessages';




/**
 * OpportunitiesCard
 * 
 * @param {*} param
 */
const OpportunitiesCard = ({
    handleOnClickGoTo,
    isThisPathActive
}) => {

    const theme = useTheme();

    return (
        <div className="menu-item-content" onClick={handleOnClickGoTo}>
            <span className={`me-lg-2 default-svg${isThisPathActive ? "--open" : ""}`}>
                <OpportunitiesIcon height={24} fill={theme.palette.primary.main} />
            </span>
            <div className="menu-item-text">
                <h5 className={`menu-item-label${isThisPathActive ? "--open" : ""}`}>
                    <IntlMessages id='label.opportunities' />
                </h5>
            </div>
        </div>
    );
}

//PropTypes
OpportunitiesCard.propTypes = {
    isThisPathActive: PropTypes.bool.isRequired,
    handleOnClickGoTo: PropTypes.func.isRequired,
};

export default memo(OpportunitiesCard);