import { memo } from 'react';
import PropTypes from 'prop-types';

/**
 * ImageCustom
 * @description obter imagens na pasta public
 * 
 * @param {string} src 
 * @param {string} alt 
 * @returns 
 */
const ImageCustom = ({ src, alt, dirRoot, className }) => {
    return (<img alt={alt}
        className={className}
        src={`${dirRoot}/${src}`}
    />);
};

//PropTypes
ImageCustom.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
};

ImageCustom.defaultProps = {
    src: `assets/images/error-404.png`,
    alt: 'efz',
    className: 'gc-img-hw-36',
    dirRoot: process.env.PUBLIC_URL,
};

//Set action functions
export default memo(ImageCustom);
