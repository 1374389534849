import React, { memo } from 'react';

import { ReactComponent as CheckIcon } from 'assets/images/icons/svg/check.svg';
import IntlMessages from 'components/util/IntlMessages';
import ImageCustom from 'components/ImageCustom';


const LanguageItem = ({ language, switchLanguage, handleRequestClose, isActive }) => {

    const { flagIcon, name, languageId } = language;

    return (
        <li
            className="pointer"
            onClick={() => {
                handleRequestClose();
                switchLanguage(language);
            }}
        >
            <div className="d-flex align-items-center">
                <ImageCustom src={flagIcon} alt={languageId} className='gc-img-w-23' />

                <h5 className="mb-0 ms-2">
                    <IntlMessages id={name} />
                </h5>
                <div className={`ms-2${isActive ? '' : ' invisible'} default-svg${isActive ? "--open" : ""}`}>
                    <CheckIcon height={24} />
                </div>
            </div>
        </li>
    );
};

export default memo(LanguageItem);
