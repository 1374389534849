import { useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import { useDispatch } from "react-redux";
import { isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';
import { AVAILABLE_COMPANIES, AVAILABLE_LANGUAGES } from 'constants/user';
import { switchLanguage } from 'actions/settings';
import { requestGetLoginSuccess } from 'actions/user';



const useRedirectToken = () => {
    const dispatch = useDispatch();

    const isValidRedirectToken = useCallback((searchParams) => {
        let token = searchParams.get('token');
        let isValid = false;
        if (isFieldDefined(token)) {
            isValid = true;
            localStorage.setItem('userToken', token);
            const jwtDecodeToken = jwt_decode(token);
            localStorage.setItem('uadfs', isDefined(jwtDecodeToken?.sessionIndex));
            let lngObj = AVAILABLE_LANGUAGES.find(obj => obj.locale === jwtDecodeToken.user.locale) ?? AVAILABLE_LANGUAGES[0];
            let companyName = AVAILABLE_COMPANIES.find(company => company.id === jwtDecodeToken.user.company_id)?.name;
            dispatch(switchLanguage(lngObj));
            dispatch(requestGetLoginSuccess(
                {
                    ...jwtDecodeToken.user,
                    companyName: companyName
                }
            ));
        }

        return isValid;
    }, [location, dispatch]) //eslint-disable-line

    return { isValidRedirectToken }
}

export default useRedirectToken;