const defaultTheme = {
    palette: {
        // For theme with only one color, set primary and secondary as the same
        primary: {
            main: 'rgb(20, 63, 71, 1)',
            bleached: 'rgb(20, 63, 71, 0.2)'
        },
        secondary: {
            main: 'rgb(20, 63, 71, 1)'
        }
        // For tertiary color, use the follwing object. Material UI components won't use it, but CSS will
        //tertiary: {}
    },
    status: {
        danger: 'orange'
    },
    typography: {
        button: {
            fontWeight: 400,
            textAlign: 'capitalize'
        },
        fontFamily: [
            "Open Sans",
            'Roboto',
            'Arial',
            'sans-serif'
        ].join(',')
    },
    logo: {
        src: require("assets/images/logos/efz.png")
    }
};

export default defaultTheme;