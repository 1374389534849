import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from "react-redux";//only case


const InjectMessage = props => {
    const { whitespaceStart, whitespaceEnd, currencySymbol } = props;
    const { user } = useSelector((state) => state);
    let _currencySymbol = currencySymbol;
    // obter o currency no userState
    if (user && user?.currency?.symbol)
        _currencySymbol = user.currency.symbol;

    let newProps = {
        ...props,
        values: {
            ...props.values,
            CURRENCY_SYMBOL: _currencySymbol //add currency symbol from redux to tag CURRENCY_SYMBOL
        }
    };

    return <>
        {whitespaceStart && ' '}
        <FormattedMessage  {...newProps} />
        {whitespaceEnd && ' '}
    </>
};

//defaultProps
InjectMessage.defaultProps = {
    whitespaceStart: false,
    whitespaceEnd: false,
    currencySymbol: 'N.A.'
};


export default injectIntl(InjectMessage, {
    withRef: false
});
