/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */

import axios from 'services/axios';
import base64 from 'react-native-base64';
import { getEndpoint } from 'services/endpoints';
import {
    URL_AUTH, URL_USER, URL_LOGOUT, URL_USER_DETAILS, URL_AUTH_MODES
} from 'constants/endpoints'
import {
    NETWORK_ERROR
} from "constants/statusCodeHttp";
import { isDefined } from 'services/util/auxiliaryUtils';


const CancelToken = axios.CancelToken;
// Cancel executor variables
let putResetPwdCancelExecutor;
let logoutCancelExecutor;
let putUserDataCancelExecutor;
let userDataCancelExecutor;

/**
 * auth
 *
 * @param data
 * @type Object {username: string, password: string}
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const auth = (data) => {
    return axios.get(getEndpoint(URL_AUTH),
        {
            headers: {
                Authorization: "Basic " + base64.encode(data.username + ":" + data.password),
                'X-Platform': 's2c',
                timeout: 15000,
            }
        }).then(response => (response))
        .catch(error => (error.response ? error.response : NETWORK_ERROR));
};

/**
 * auth
 *
 * @param data
 * @type Object {username: string, password: string}
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const authV2 = (data) => {
    return axios.get(`${getEndpoint(URL_AUTH_MODES)}/${data.username}`,
        {
            headers: {
                Authorization: "Basic " + base64.encode(process.env.REACT_APP_RECOVEY_BASE64), 
                'X-Platform': 's2c',
                timeout: 15000,
            }
        }).then(response => (response))
        .catch(error => (error.response ? error.response : NETWORK_ERROR));
};


export const getResetPassword = (email) => {
    return axios.get(
        getEndpoint(URL_USER) + '/credentials-email/' + email,
        {
            headers: {
                Authorization: "Basic " + base64.encode(process.env.REACT_APP_RECOVEY_BASE64),
                'X-Platform': 's2c',
                timeout: 15000,
            }
        }
    ).then(response => (response)).catch(error =>
        (error.response ? error.response : NETWORK_ERROR)
    );
};

export const putRedefinePassword = (userID, body) => {
    putResetPwdCancelExecutor && putResetPwdCancelExecutor();
    return axios.put(
        `${getEndpoint(URL_USER)}/${userID}`,
        body,
        {
            cancelToken: new CancelToken(function executor(c) {
                putResetPwdCancelExecutor = c;
            })
        }
    ).then(response => (response)).catch(error =>
        (error.response ? error.response : NETWORK_ERROR)
    );
};


export const getLogoutADFS = (token, nameid, sessionindex) => {
    let qData = isDefined(nameid) ? `?nameid=${nameid}&sessionindex=${sessionindex}` : '';
    logoutCancelExecutor && logoutCancelExecutor();
    localStorage.removeItem('userToken');
    // axios.defaults.headers.common['Authorization'] = `JWT ${token}`;

    return axios.get(
        `${getEndpoint(URL_LOGOUT)}${qData}`,
        {
            headers: {
                Authorization: `JWT ${token}`,
                'X-Platform': 's2c',
                timeout: 15000,
            },
            cancelToken: new CancelToken(function executor(c) {
                logoutCancelExecutor = c;
            })
        }
    ).then(response => (response)).catch(error =>
        (error.response ? error.response : NETWORK_ERROR)
    );
}

// SECTION: User details

export const getUserData = (userID) => {
    userDataCancelExecutor && userDataCancelExecutor();
    return axios.get(
        `${getEndpoint(URL_USER_DETAILS)}/${userID}`,
        {
            /* headers: {
                Authorization: `JWT ${localStorage.getItem('userToken')}`,
                'X-Platform': 's2c',
                timeout: 15000,
            }, */
            cancelToken: new CancelToken(function executor(c) {
                userDataCancelExecutor = c;
            })
        }
    )
    .then(response => (response))
    .catch(error => (error.response ? error.response : NETWORK_ERROR));
}

export const putUserData = (userID, body) => {
    putUserDataCancelExecutor && putUserDataCancelExecutor();
    return axios.put(
        `${getEndpoint(URL_USER_DETAILS)}/${userID}`,
        body,
        {
            cancelToken: new CancelToken(function executor(c) {
                putUserDataCancelExecutor = c;
            })
        }
    )
    .then(response => (response))
    .catch(error =>
        (error.response ? error.response : NETWORK_ERROR)
    );
}