//Libraries
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from "react-toastify";

//Components
import LoginForm from 'components/login/Form';
import IntlMessages from 'components/util/IntlMessages';

//Pages
import Footer from 'pages/Restricted/shared/Footer';

//Actions
import {
    requestGetLogin,
    setUserAuthenticated
} from 'actions/user';

//Selectors
import {
    isRequesting,
    getIsUserAuthenticated,
    getUserTypeID,
    getAuthShowPassword
} from "selectors/user";

//Services
import { isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';

//Icons
import { ReactComponent as BackgroundLeft } from 'assets/images/auth/svg/graphic-left.svg';
import { ReactComponent as BackgroundRight } from 'assets/images/auth/svg/graphic-right.svg';
import { ReactComponent as FieldLogo } from 'assets/images/logos/svg/eff-platform.svg';

//Constants
import { BASENAME_URL_OPPORTUNITIES } from "constants/settings";

const Login = ({
    userTypeID,
    isRequesting,
    isUserAuthenticated,
    showPassword,
    // fnc
    requestGetLogin,
    setUserAuthenticated
}) => {

    let navigate = useNavigate();

    // componentWillUnmountWithUseEffect
    useEffect(() => {
        return () => {
            toast.dismiss(); // dismiss all notifications
        }
    }, []); //eslint-disable-line

    // if user is authenticated, redirect
    useEffect(() => {
        let hasToken = isFieldDefined(localStorage?.getItem('userToken'));
        if (hasToken && isUserAuthenticated && isDefined(userTypeID)) {
            navigate(BASENAME_URL_OPPORTUNITIES);
        }

        if (!hasToken && isUserAuthenticated) setUserAuthenticated();
    }, [isUserAuthenticated]); //eslint-disable-line

    //handleOnSubmit
    const handleOnSubmit = ({ username, password, withPassword }) => {
        requestGetLogin({
            username,
            password,
            withPassword
        });
    };

    return (
        <div className={'app-main-login'}>
            <section className="login-svg-bg">
                <div className="login-svg-left">
                    <BackgroundLeft />
                </div>
                <div className={"login-svg-right"}>
                    <BackgroundRight />
                </div>
            </section>
            <section className="login-top-section">
                <div>
                    <div className="app-login-container animated fadeIn animation-duration-2">
                        <div className="login-content m-4">
                            {/* Header */}
                            <div className='login-header'>
                                <FieldLogo />
                            </div>
                            {/* Body */}
                            <div className="login-body">
                                <div className="login-body-text">
                                    <h4><IntlMessages id={'page.login.subtitle'} /></h4>
                                </div>
                                <LoginForm
                                    isRequesting={isRequesting}
                                    showPassword={showPassword}
                                    handleOnSubmit={handleOnSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer isUserAuthenticated={isUserAuthenticated} />
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    return {
        userTypeID: getUserTypeID(user),
        isRequesting: isRequesting(user),
        isUserAuthenticated: getIsUserAuthenticated(user),
        showPassword: getAuthShowPassword(user)
    }
};

export default connect(mapStateToProps, {
    requestGetLogin,
    setUserAuthenticated
})(Login);
