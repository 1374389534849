import { AVAILABLE_LOCALES } from 'constants/user';
import enMessages from 'lngProvider/locales/en_GB.json';

const enLang = {
  messages: {
    ...enMessages
  },
  locale: AVAILABLE_LOCALES.enGB
};
export default enLang;
