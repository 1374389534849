
import { isDefined } from 'services/util/auxiliaryUtils';
import clone from "fast-copy";
import jwt_decode from "jwt-decode";
import { INITIAL_USER, USERS_TYPE_ID, AVAILABLE_COMPANIES } from 'constants/user';
import { getBaseURL } from './settings';



/**
 * getUserType
 *
 * @param typeID
 * @returns {string}
 */
export const getUserType = (typeID) => {

    switch (typeID) {
        case USERS_TYPE_ID.MANAGER:
            return 'Manager';
        case USERS_TYPE_ID.SUPERVISOR:
            return 'Supervisor';
        case USERS_TYPE_ID.AGENT:
            return 'Agent';
        case USERS_TYPE_ID.CHANNEL:
            return 'Channel Assistant';
        case USERS_TYPE_ID.CLIENT:
            return 'Client';
        default:
            console.log("[ERROR]: USER TAG TRANSLATION MISSING");
            return "";
    }
};


/**
 * isTokenValid
 *
 * @returns {boolean}
 */
export const isTokenValid = () => (isDefined(localStorage?.getItem('userToken')));

/**
 * getInitialUser
 */
export const getInitialUser = () => {
    let userToken = localStorage?.getItem('userToken');
    const _isTokenValid = isTokenValid();
    let user = (_isTokenValid) ? jwt_decode(userToken).user : null;
    let companyObj = (_isTokenValid) ? AVAILABLE_COMPANIES.find(company => company.id === user.company_id) : { name: null };


    return clone({
        ...clone(INITIAL_USER),
        ...user,
        auth: {
            isRequesting: false,
            isUserAuthenticated: _isTokenValid
        },
        companyName: _isTokenValid && companyObj?.name ? companyObj.name : null
    });
}

export function goToAuthADFS(uri = null) {
    let uriAuthADFS = `${getBaseURL()}auth/adfs?redirect_to=${getBaseURL(true)}`;
    // let uriAuthADFS = `${getBaseURL()}auth/adfs?redirect_to=http://localhost:8015`;//DEV
    if (isDefined(uri)) {
        uriAuthADFS = uri;
    }
    return window.location.replace(uriAuthADFS);
}
