/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Reducers - User
 *
 * @version 20200916
 * @since 20200916 Initial release
 *
 */

import clone from "fast-copy";
import {
    getInitialUser
} from 'services/user';
import {
    INITIAL_USER,
    MODIFY_USER_DATA,
    REQUEST_GET_LOGIN,
    REQUEST_GET_LOGIN_FAIL,
    REQUEST_GET_LOGIN_SUCCESS,
    LOGOUT_USER,
    SET_USER_AUTHENTICATED_FALSE,
    REQUEST_GET_LOGIN_BY_SSO_FALSE,
    RESET_AUTH_IS_REQUESTING,
    RESET_AUTH_SHOW_PASSWORD
} from 'constants/user';

//Inital State
const _INITIAL_USER = getInitialUser();



const user = (state = _INITIAL_USER, action) => {
    switch (action.type) {
        case REQUEST_GET_LOGIN: {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    isRequesting: true,
                    isUserAuthenticated: false,
                }
            }
        }
        case RESET_AUTH_IS_REQUESTING: {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    isRequesting: false,
                }
            }
        }
        case RESET_AUTH_SHOW_PASSWORD: {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    showPassword: false
                }
            }
        }
        case REQUEST_GET_LOGIN_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                auth: {
                    ...state.auth,
                    isRequesting: false,
                    isUserAuthenticated: true,
                    showPassword: false
                }
            }
        }
        case REQUEST_GET_LOGIN_BY_SSO_FALSE: {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    isRequesting: false,
                    isUserAuthenticated: false,
                    showPassword: true
                }
            }
        }
        case REQUEST_GET_LOGIN_FAIL: {
            return {
                ...clone(INITIAL_USER)
            }
        }
        case LOGOUT_USER:
        case SET_USER_AUTHENTICATED_FALSE: {
            return {
                ...clone(INITIAL_USER)
            }
        }
        case MODIFY_USER_DATA: {
            return {
                ...state,
                ...action.payload,
            }
        }
        default:
            return state;
    }
};

export default user;