/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Page Header
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */
//Libraries
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux'
import PropTypes from "prop-types";
import { useNavigate, Link } from "react-router-dom";

import useTheme from '@mui/material/styles/useTheme';

//Selectors
import {
	getLocale
} from "selectors/settings";
import {
	getUserCompanyID,
	getUserUsername,
	getUserTypeID,
	getUserUserManagement
} from "selectors/user";

//Actions
import {
	setDarkTheme,
	switchLanguage
} from "actions/settings";
import {
	userSignOut,
	userSignOutADFS
} from "actions/user";

//constants
import {
	AVAILABLE_COMPANIES
} from 'constants/user';
import {
	BASENAME_URL,
	BASENAME_URL_PROCESSES,
	BASENAME_URL_OPPORTUNITIES,
	BASENAME_URL_USERS,
	BASENAME_URL_USER
} from 'constants/settings';

//our components
import UserCard from 'components/header/UserCard';
import ProcessesCard from 'components/header/ProcessesCard';
import OpportunitiesCard from 'components/header/OpportunitiesCard';
import LangSwitcherCard from 'components/header/LangSwitcherCard';

//svgs
import { ReactComponent as PoweredByIcon } from 'assets/images/logos/svg/powered-color.svg';
import { getBoolean, isFieldDefined } from 'services/util/auxiliaryUtils';
import LoadingDialog from 'components/LoadingDialog';
import UsersCard from 'components/header/UsersCard';
import LazyImage from 'components/util/LazyImage';

/**
 * @context Page to Header
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Header = ({
	userTypeID,
	userCompanyID,
	userUsername,
	userUserManagement,
	isUADFS,
	// fnc
	setDarkTheme,
	userSignOut,
	switchLanguage,
	userSignOutADFS,
	...props
}) => {
	let navigate = useNavigate();
	const [isOpenInfoADFSDialog, setIsOpenInfoADFSDialog] = useState(false);
	const [isOpenLangSwitcher, setIsOpenLangSwitcher] = useState(false);
	const [isOpenUserInfo, setIsOpenUserInfo] = useState(false);

	const theme = useTheme();
	let currentCompany = useRef(AVAILABLE_COMPANIES.find(company => company.id === userCompanyID));


	useEffect(() => {
		window.addEventListener('resize', () => {
		});
	}, []);//eslint-disable-line


	// langSwitcherCard
	const langCode = props.locale.icon;
	const handleToggleLangSwitcherCard = useCallback(() => (setIsOpenLangSwitcher(!isOpenLangSwitcher)), [isOpenLangSwitcher]);//eslint-disable-line
	const handleSwitchLanguage = useCallback((locale) => (switchLanguage(locale)), []);//eslint-disable-line

	// UserCard
	const handleToggleUserCard = useCallback(() => (setIsOpenUserInfo(!isOpenUserInfo)), [isOpenUserInfo]);//eslint-disable-line
	const handleUserSignOut = useCallback(() => {
		if (isUADFS) setIsOpenInfoADFSDialog(true);
		return (isUADFS ? userSignOutADFS() : userSignOut())
	}, [isUADFS]); //eslint-disable-line
	const handleEditUserData = useCallback(async () => {
		handleToggleUserCard();
		return navigate(BASENAME_URL_USER);
	}, [isOpenUserInfo]);//eslint-disable-line


	// ProcesesCard
	const handleOnClickGoToProcesses = useCallback(() => (navigate(BASENAME_URL_PROCESSES)), []);//eslint-disable-line

	// UsersCard
	const handleOnClickGoToUsers = useCallback(() => (navigate(BASENAME_URL_USERS)), []);//eslint-disable-line

	// OpportunitiesCard
	const handleOnClickGoToOpportunities = useCallback(() => (navigate(BASENAME_URL_OPPORTUNITIES)), []);//eslint-disable-line

	return (
		<nav className="app-header">
			<div className="content-wrapper pt-0 d-flex flex-row h-100">
				<div className="header-logo me-3" >
					<Link to={`${BASENAME_URL}app/opportunities`} >
						<LazyImage src={theme?.logo?.src} alt="efz" sizeImg={60} customClassName='placeholder-logo' />
						{
							<>
								{!!currentCompany.current?.logoCompanyName &&
									<span className="ms-3 w-100">
										<p>{currentCompany.current?.logoCompanyName}</p>
									</span>
								}
								{currentCompany.current?.usePoweredBy &&
									<PoweredByIcon className="ms-3 w-100" />
								}
							</>
						}
					</Link>
				</div>

				<div className="header-menu" >
					<ul className="menu-list">
						{/*list of opportunities*/}
						<li className="menu-item py-3">
							<OpportunitiesCard
								handleOnClickGoTo={handleOnClickGoToOpportunities}
								isThisPathActive={window.location.pathname === BASENAME_URL_OPPORTUNITIES}
							/>
						</li>
						{/*list of processes*/}
						<li className="menu-item py-3">
							<ProcessesCard
								handleOnClickGoTo={handleOnClickGoToProcesses}
								isThisPathActive={window.location.pathname === BASENAME_URL_PROCESSES}
							/>
						</li>
						{/* list of users */}
						{/* TODO: only show to specific type of user */}
						{userUserManagement &&
							<li className="menu-item py-3">
								<UsersCard
									handleOnClickGoTo={handleOnClickGoToUsers}
									isThisPathActive={window.location.pathname === BASENAME_URL_USERS}
								/>
							</li>
						}
						{/*lang*/}
						<li className={`menu-item${isOpenLangSwitcher ? '-expanded' : ''}`}>
							<LangSwitcherCard
								isOpen={isOpenLangSwitcher}
								handleToggle={handleToggleLangSwitcherCard}
								handleSwitchLanguage={handleSwitchLanguage}
								langCode={langCode}
							/>
						</li>
						{/*user*/}
						<li className={`menu-item${isOpenUserInfo ? '-expanded' : ''}`}>
							<UserCard
								isOpen={isOpenUserInfo}
								isThisPathActive={window.location.pathname === BASENAME_URL_USER}
								handleToggle={handleToggleUserCard}
								handleUserSignOut={handleUserSignOut}
								handleEditUserData={handleEditUserData}
								userTypeID={userTypeID}
								username={userUsername}
								setDarkTheme={setDarkTheme}
							/>
						</li>
					</ul>
				</div>
			</div>
			<LoadingDialog
				isOpen={isOpenInfoADFSDialog}
				title={'label.closing'}
				subtitle={'label.pleaseWait'}
			/>
		</nav>
	);

};


Header.propTypes = {
	userCompanyID: PropTypes.number.isRequired,
	userUsername: PropTypes.string.isRequired,
	userUserManagement: PropTypes.bool.isRequired,
	locale: PropTypes.shape({
		icon: PropTypes.string.isRequired,
		languageId: PropTypes.string.isRequired,
		locale: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
};


const mapStateToProps = ({ settings, user }) => {
	return {
		locale: getLocale(settings),
		userTypeID: getUserTypeID(user),
		userCompanyID: getUserCompanyID(user),
		userUsername: getUserUsername(user),
		userUserManagement: getUserUserManagement(user),
		isUADFS: isFieldDefined(localStorage.getItem('uadfs')) ? getBoolean(localStorage.getItem('uadfs')) : false,
	}


};

export default connect(mapStateToProps,
	{
		switchLanguage,
		setDarkTheme,
		userSignOut,
		userSignOutADFS
	})(Header);
