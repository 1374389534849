import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
//Icons
import { ReactComponent as BackgroundLeft } from 'assets/images/auth/svg/graphic-left.svg';
import { ReactComponent as BackgroundRight } from 'assets/images/auth/svg/graphic-right.svg';
import LoadingDialog from 'components/LoadingDialog';
import { userSignOutADFS } from 'actions/user';

const TokenExpiryADFS = () => {
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(userSignOutADFS())
    }, []); //eslint-disable-line


    return (
        <div className={'app-main-login'}>
            <section className="login-svg-bg">
                <div className="login-svg-left">
                    <BackgroundLeft />
                </div>
                <div className={"login-svg-right"}>
                    <BackgroundRight />
                </div>
            </section>
            <LoadingDialog
                isOpen={true}
                title={'label.closing'}
                subtitle={'label.pleaseWait'}
            />

        </div>
    );
};

export default (TokenExpiryADFS);
