/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @see react-intl docs: https://github.com/formatjs/react-intl
 *
 * @version 20200915
 * @since 20200915 Initial release
 *
 */

import ptPTLang from './entries/pt-PT';
import enGBLang from './entries/en-GB';

import {defineMessages} from 'react-intl';
import { AVAILABLE_LOCALES } from 'constants/user';

const AppLocale = {
  [AVAILABLE_LOCALES.ptPT]: ptPTLang,
  [AVAILABLE_LOCALES.enGB]: enGBLang,
};

defineMessages(AppLocale[AVAILABLE_LOCALES.ptPT].data);
defineMessages(AppLocale[AVAILABLE_LOCALES.enGB].data);

export default AppLocale;