import axios from "axios";
import jwt_decode from 'jwt-decode';
import { toast } from "react-toastify";
//Constants
import { CONFIG_HEADERS } from "constants/endpoints";
import { BASENAME_URL_ADFS_TOKEN_EXPIRY, BASENAME_URL_LOGIN } from "constants/settings";
import {
    MSG_SERVER_ERROR,
    OK,
    SESSION_EXPIRED,
    TOAST_CHECK_STATUS_ERROR_ID,
    TOAST_MSG_SERVER_ERROR_ID,
    TOAST_SESSION_EXPIRED_ID,
    UNAUTHORIZED,
} from "constants/statusCodeHttp";
// Other
import { history } from 'store';
import { getBoolean, isDefined, isExistWord, isFieldDefined } from "services/util/auxiliaryUtils";
import { StatusCodes } from "http-status-codes";
import { SentryCaptureException } from "./util/sentry";

//#region agentkeepalive //TODO: disabled tmp
// const HttpAgentKeepAlive = require('agentkeepalive');
// const HttpsAgentKeepAlive = require('agentkeepalive').HttpsAgent;
// const agentkeepaliveConfig = {
//     httpAgent: new HttpAgentKeepAlive({
//         maxSockets: 512,
//         maxFreeSockets: 100,
//         timeout: 60000, // active socket keepalive for 60 seconds
//         freeSocketTimeout: 30000, // free socket keepalive for 30 def
//     }),
//     httpsAgent: new HttpsAgentKeepAlive({
//         maxSockets: 512,
//         maxFreeSockets: 100,
//         timeout: 60000, // active socket keepalive for 60 seconds
//         freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
//     })
// };
//#endregion agentkeepalive

//* MockAdapter AXIOS INSTANCE *\\
export const axiosMockAdapterInstance = axios.create();


//* AXIOS STATIC INSTANCE *\\
//#region Configure headers
axios.defaults.headers.common['Content-Type'] = CONFIG_HEADERS['Content-Type'];
axios.defaults.headers.common['cancellable'] = CONFIG_HEADERS['cancellable'];
axios.defaults.headers.common['timeout'] = CONFIG_HEADERS['timeout'];
// axios.defaults.httpAgent = agentkeepaliveConfig.httpAgent;
// axios.defaults.httpsAgent = agentkeepaliveConfig.httpsAgent;
//#endregion

//#region Request interceptor
axios.interceptors.request.use((config) => {

    //#region public requests
    let isAuth = (isExistWord(config?.url, 'auth'));
    let isresetPwd = (isExistWord(config?.url, 'credentials-email'));
    if (isAuth) return config;
    if (isresetPwd) return config;
    //#endregion public requests

    let isUADFS = (isFieldDefined(localStorage.getItem('uadfs')) ? getBoolean(localStorage.getItem('uadfs')) : false);
    try {

        let token = localStorage.getItem('userToken');
        let nowDate = Date.now() / 1000;
        let jwtDecodedToken = jwt_decode(token);
        let tokenExpiryDate = jwtDecodedToken.exp;

        if (nowDate >= tokenExpiryDate) { // If token is valid but already expired
            if (!isUADFS) {
                localStorage.removeItem('userToken');
                toast.warning(SESSION_EXPIRED, {
                    toastId: TOAST_SESSION_EXPIRED_ID // avoid duplicate notifications
                });
            }
            history.push(isUADFS ? BASENAME_URL_ADFS_TOKEN_EXPIRY : BASENAME_URL_LOGIN);

            return Promise.reject("Logging out due to expired token");
        }

        config.headers['Authorization'] = "JWT " + localStorage.getItem('userToken');
        return config;
    }
    catch (error) {  // If token is invalid
        if (!isUADFS) {
            localStorage.removeItem('userToken');
            toast.warning(SESSION_EXPIRED, {
                toastId: TOAST_SESSION_EXPIRED_ID // avoid duplicate notifications
            });
        }
        history.push(isUADFS ? BASENAME_URL_ADFS_TOKEN_EXPIRY : BASENAME_URL_LOGIN);

        return Promise.reject("Logging out due to invalid token: ", error);
    }
}, (error) => {
    return Promise.reject(error);
});
//#endregion


//#region Response interceptor
axios.interceptors.response.use(undefined, (data) => {

    if (!isDefined(data?.response?.status)) return;

    switch (data?.response?.status) {
        case OK:
            break;
        case UNAUTHORIZED: //UNAUTHORIZE > LOGOUT
            let isUADFS = (isFieldDefined(localStorage.getItem('uadfs')) ? getBoolean(localStorage.getItem('uadfs')) : false);
            if (!isUADFS) {
                localStorage.removeItem('userToken');
                toast.warning(SESSION_EXPIRED, {
                    toastId: TOAST_SESSION_EXPIRED_ID // avoid duplicate notifications
                });

            }
            history.push(isUADFS ? BASENAME_URL_ADFS_TOKEN_EXPIRY : BASENAME_URL_LOGIN);
            break;
        case StatusCodes.BAD_GATEWAY:
        case StatusCodes.SERVICE_UNAVAILABLE:
        case StatusCodes.INTERNAL_SERVER_ERROR: {
            //#region monitorization 50X
            let endpoint = data?.config?.url ?? null;
            let payload = data?.response?.config?.data ?? null;
            const message = `API-Warning | status: ${data?.response?.status} | message: ${data?.response?.data?.message ?? data?.response?.data?.data}`;
            SentryCaptureException({
                level: 3,
                message,
                fingerprint: message,
                context: { endpoint },
                extrasContext: {
                    endpoint,
                    payload,
                    response: {
                        ...data?.response?.data,
                    },
                    axiosCode: data?.code ?? null,
                    axiosMessage: data?.message ?? null,
                    method: data?.config?.method ?? null,
                },
                tags: {
                    endpoint,
                    statusCode: data?.response?.status,
                    axiosCode: data?.code ?? null,
                }
            });
            //#endregion
            toast.error(MSG_SERVER_ERROR, {
                toastId: TOAST_MSG_SERVER_ERROR_ID
            });
            break;
        }
        case StatusCodes.NOT_FOUND:
        case StatusCodes.TOO_MANY_REQUESTS:
        case StatusCodes.BAD_REQUEST: {
            let endpoint = data?.config?.url ?? null;;
            let payload = data?.response?.config?.data ?? null;
            const message = `API-Error | status: ${data?.response?.status} | message: ${data?.response?.data?.message ?? data?.response?.data?.data}`;
            //#region monitorization status 400
            SentryCaptureException({
                level: 2,
                message,
                fingerprint: message,
                extrasContext: {
                    endpoint,
                    payload,
                    response: {
                        ...data?.response?.data,
                    },
                    axiosCode: data?.code ?? null,
                    axiosMessage: data?.message ?? null,
                    method: data?.config?.method ?? null,
                },
                tags: {
                    endpoint,
                    statusCode: data?.response?.status,
                    axiosCode: data?.code ?? null,
                }
            });
            //#endregion
            break;
        }
        default:
            let isHeadSupportDocs = (data.response.config.method === 'head');//only support-docsD
            if (!isHeadSupportDocs) {
                toast.error(data.response?.data?.message ?? data.response.message, {
                    toastId: TOAST_CHECK_STATUS_ERROR_ID
                });
                //#region monitorization status 4xx || 5xx
                let endpoint = data?.config?.url ?? null;
                if ([
                    StatusCodes.NOT_FOUND,
                    StatusCodes.TOO_MANY_REQUESTS,
                    StatusCodes.GATEWAY_TIMEOUT,
                ].includes(data?.response?.status)) {
                    const payload = data?.response?.config?.data ?? null;
                    const titleMessage = (StatusCodes.GATEWAY_TIMEOUT === data?.response?.status)
                        ? 'GATEWAY_TIMEOUT'
                        : data?.response?.data?.message ?? (data?.response?.data?.data ?? data?.response?.data);

                    const message = `API-Warning | status: ${data?.response?.status} | message: ${titleMessage}`;
                    SentryCaptureException({
                        level: 3,
                        message,
                        fingerprint: message,
                        extrasContext: {
                            endpoint,
                            payload,
                            method: data?.config?.method ?? null,
                            response: {
                                ...data?.response?.data,
                            },
                            axiosCode: data?.code ?? null,
                            axiosMessage: data?.message ?? null,
                        },
                        tags: {
                            endpoint,
                            statusCode: data?.response?.status,
                            axiosCode: data?.code ?? null,
                        }
                    });
                }
                //#endregion
            }
            break;
    }
    return data.response;
});
//#endregion


export default axios;