/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Selector User
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */
import { createSelector } from 'reselect';
// user
export const getUserName = createSelector(state => state?.name, name => name);
export const getUserID = createSelector(state => state?.id, id => id);
export const getUserTypeID = createSelector(state => state?.user_type_id, user_type_id => user_type_id);
export const getUserCompanyID = createSelector(state => state?.company_id, company_id => company_id);
export const getUserUsername = createSelector(state => state?.username, username => username);
export const getUserUserManagement = createSelector(state => state?.user_management, user_management => user_management);

// auth
export const getIsUserAuthenticated = createSelector(state => state?.auth.isUserAuthenticated, isUserAuthenticated => isUserAuthenticated);
export const isRequesting = createSelector(state => state?.auth.isRequesting, isRequesting => isRequesting);
export const getAuthShowPassword = createSelector(state => state?.auth.showPassword, showPassword => showPassword);
// locale
export const getUserLocale = createSelector(state => state?.locale, locale => locale);
export const getUserCurrencyCode = createSelector(state => state?.currency?.code, code => code);
export const getUserCurrencySymbol = createSelector(state => state?.currency?.symbol, symbol => symbol);
export const isChangedPassword = createSelector(state => state?.changed_pass, changed_pass => changed_pass);
export const getIsUserDemo = createSelector(state => state?.is_demo, is_demo => is_demo ?? false);

