

import { EMAIL_REGEXP, TELEPHONE_REGEXP, FLOAT_REGEXP, isDefined, PASSWORD_CHECK } from "./auxiliaryUtils";

export const VALIDATION_RULES = {
    REQUIRED: 'required',
    STRING: 'string',
    EMAIL: 'email',
    TELEPHONE: 'telephone',
    NUMBER: 'number',
    POSITIVE: 'positive',
    MIN: 'min',
    MAX: 'max',
    MAXNINCLUDED: 'maxNotIncluded',
    MINNINCLUDED: 'minNotIncluded',
    NOT_NUMBER: 'notNumber',
    PASSWORD: 'password',
    PASSWORD_LOWERCASE: 'passwordLowerCase',
    PASSWORD_UPPERCASE: 'passwordUpperCase',
    PASSWORD_NUMBER: 'passwordNumber'
}

/**
 * Description: Validate fields according to rules 
 * Valid rules: rules, string, email, telephone
 *
 * @param rulesArray - array of different validation rules
 * @returns {object}
 */
const validate = (rulesArray, options) => {
    //Validate if array and length greater than 0
    if (!isDefined(rulesArray) || !Array.isArray(rulesArray) || rulesArray.length === 0) return {};
    //Initialize Rules Object
    let rules = {};
    //Initialize Validate functions
    rules.validate = {}
    //Fields
    rulesArray.forEach(rule => {
        switch (rule) {
            case VALIDATION_RULES.REQUIRED:
                rules.required = 'yup.message.required';
                break;
            case VALIDATION_RULES.STRING:
                rules.validate.string = value => typeof value === "string" || "yup.type.string";
                break;
            case VALIDATION_RULES.EMAIL:
                rules.pattern = {
                    value: EMAIL_REGEXP,
                    message: 'yup.message.email'
                };
                break;
            case VALIDATION_RULES.TELEPHONE:
                rules.pattern = {
                    value: TELEPHONE_REGEXP,
                    message: 'yup.message.telephone'
                };
                break;
            case VALIDATION_RULES.POSITIVE:
                rules.min = {
                    value: 0,
                    message: 'yup.message.number.positive'
                };
                break;
            case VALIDATION_RULES.MIN:
                rules.min = {
                    value: options.min,
                    message: 'yup.message.number.min'
                };
                break;
            case VALIDATION_RULES.MAX:
                rules.max = {
                    value: options.max,
                    message: 'yup.message.number.max'
                };
                break;
            case VALIDATION_RULES.MAXNINCLUDED:
                rules.validate.maxNIncluded = value => value < options.max || "yup.message.number.maxNIncluded";
                break;
            case VALIDATION_RULES.MINNINCLUDED:
                rules.validate.minNIncluded = value => value > options.min || "yup.message.number.minNIncluded";
                break;
            case VALIDATION_RULES.NUMBER:
                rules.pattern = {
                    value: FLOAT_REGEXP,
                    message: 'yup.message.number'
                };
                break;
            case VALIDATION_RULES.NOT_NUMBER:
                rules.validate.notNumber = value => value !== options?.number || options?.message;
                break;
            case VALIDATION_RULES.PASSWORD:
                rules.pattern = {
                    value: PASSWORD_CHECK.PASSWORD_IS_VALID,
                    message: 'yup.message.email'
                };
                break;
            case VALIDATION_RULES.PASSWORD_LOWERCASE:
                rules.pattern = {
                    value: PASSWORD_CHECK.PASSWORD_HAS_LOWERCASE_REGEXP,
                    message: 'yup.message.password.required.lowerCase'
                };
                break;
            case VALIDATION_RULES.PASSWORD_UPPERCASE:
                rules.pattern = {
                    value: PASSWORD_CHECK.PASSWORD_HAS_UPPERCASE_REGEXP,
                    message: 'yup.message.password.required.upperCase'
                };
                break;
            case VALIDATION_RULES.PASSWORD_NUMBER:
                rules.pattern = {
                    value: PASSWORD_CHECK.PASSWORD_HAS_DIGIT_REGEXP,
                    message: 'yup.message.password.required.number'
                };
                break;
            default:
                break;
        }
    });

    return rules;
}


export default validate;