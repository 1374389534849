import clone from 'fast-copy';
import { EMAIL_REGEXP, intlMessages } from "services/util/auxiliaryUtils";
import validate, { VALIDATION_RULES } from 'services/util/validate';
import { SPV_INPUT_NAMES } from './spv';

export const ME_INPUTS_DEFAULTS = {
    solution_type_id: '',

    //PROFILE
    is_client: '',
    has_electric_vehicle: '',
    brand_id: '',
    model_id: '',
    daily_distance_km: 10,

    //SPACE
    parking_type_id: '',
    contracted_power_id: '',
    electric_installation_type_id: '',
    network_connection: '',
    has_me_adaptation: "",
    box_connection_type_id: '',
    evc_electrical_chain_id: '',
    max_charging_power_id: '',
    max_charging_power: '',
    user_charging_power: '',

    //DISTANCE
    house_level: '',
    garage_level: '',
    value: '',
    distance_network_connection: '',
    installation_site_id: '',

    //PROPOSAL
    name: '',
    tax_number: '',
    email: '',
    phone_number: '',
    postal_code: '',
    locality: '',
    personal_data_yield: '',

    //FACILITY
    //is_client: '',
    installation_connection_type_id: '',
    //electric_installation_type_id
    contracted_power: '',
    maximum_contracted_power: '',
    total_consumption: '',
    power_start_date: null,
    power_end_date: null,

    //SWITCHBOARD
    qsc_location: '',
    distance_qsc_stairs: '',
    single_phase_percentage: 75,
    smart_charging_mode: '',

    // GARAGE
    location: '',
    parking_spots: '',
    area: '',
    distance_parking_spots_id: '',
    max_distance_parking_spots: '',
    min_distance_parking_spots: '',
    distance_parking_spot_qp_id: '',
    max_distance_parking_spot_qp: '',
    min_distance_parking_spot_qp: '',
    distance_qp_to_stairs: '',

    //NIF
    nif: ''
}



export const TYPOLOGY_TYPE_IDS = {
    INDIVIDUAL: 1,
    COLLECTIVE: 2
}

export const PARKING_TYPE_IDS = {
    CONDOMINIUM_PRIVATE_BOX: 1,
    CONDOMINIUM_SHARED: 2,
    HOUSING_PRIVATE: 3
}

export const DISTANCE_TYPE = {
    RIGHT_MEASUREMENT: 1,
    IDEA_MEASUREMENT: 2
}

export const ME_INDIVIDUAL_STEPS = {
    TYPOLOGY: '0',
    PROFILE: '1',
    SPACE: '2',
    DISTANCE: '3',
    SIMULATION: '4',
    PROPOSAL: '5',
}
export const ME_COLLECTIVE_STEPS = {
    TYPOLOGY: '0',
    FACILITY: '1',
    SWITCHBOARD: '2',
    GARAGE: '3',
    ADAPTATION: '4',
    PROPOSAL: '5',
}

export const ME_RANGES = {
    PLUG_IN_EDP: 1,
    LIGHT_CHARGER_EDP: 2,
    STANDARD_CHARGER_EDP: 3,
    PREMIUM_CHARGER_EDP: 4,
    SMART_CHARGER_EDP: 5
}

export const ME_ELECTRIC_INSTALLATION_TYPE_IDS = {
    MONOPHASIC: 1,
    THREEPHASIC: 2,
    DONT_KNOW: 3
}

export const ME_BOX_CONNECTION_TYPE_ID = {
    FRACTION: 1,
    COMMON_SERVICES: 2,
    DONT_KNOW: 3,
}

export const ME_SIMULATION_STEPS = {
    CARDFORM: 1,
    CONTACTFORM: 2,
    CONFIRMATION: 3,
}

export const MAIN_ELECTRICAL_PANEL_IDS = {
    YES: 1,
    NO: 2,
    DONT_KNOW: 3,
}

export const ME_INPUTS_INDIVIDUAL_STEPS = [
    { label: "label.typology", iconLabel: ME_INDIVIDUAL_STEPS.TYPOLOGY, order: 0 },
    { label: "label.profile", iconLabel: ME_INDIVIDUAL_STEPS.PROFILE, order: 1 },
    { label: "label.space", iconLabel: ME_INDIVIDUAL_STEPS.SPACE, order: 2 },
    { label: "label.distance", iconLabel: ME_INDIVIDUAL_STEPS.DISTANCE, order: 3 },
    { label: "label.simulation", iconLabel: ME_INDIVIDUAL_STEPS.SIMULATION, order: 4 },
    { label: "label.proposal", iconLabel: ME_INDIVIDUAL_STEPS.PROPOSAL, order: 5 },
];

export const ME_INPUTS_COLLECTIVE_STEPS = [
    { label: "label.typology", iconLabel: ME_COLLECTIVE_STEPS.TYPOLOGY, order: 0 },
    { label: "label.facility", iconLabel: ME_COLLECTIVE_STEPS.FACILITY, order: 1 },
    { label: "label.switchboard", iconLabel: ME_COLLECTIVE_STEPS.SWITCHBOARD, order: 2 },
    { label: "label.garage", iconLabel: ME_COLLECTIVE_STEPS.GARAGE, order: 3 },
    { label: "label.adaptation", iconLabel: ME_COLLECTIVE_STEPS.ADAPTATION, order: 4 },
    { label: "label.proposal", iconLabel: ME_COLLECTIVE_STEPS.PROPOSAL, order: 5 }
];


export const ME_INPUT_NAMES = {
    //PROFILE
    IS_CLIENT: 'is_client',
    HAS_ELECTRIC_VEHICLE: 'has_electric_vehicle',
    BRAND_ID: 'brand_id',
    MODEL_ID: 'model_id',
    DAILY_DISTANCE_KM: 'daily_distance_km',

    //SPACE
    PARKING_TYPE_ID: 'parking_type_id',
    BOX_CONNECTION_TYPE_ID: 'box_connection_type_id',
    CONTRACTED_POWER_ID: 'contracted_power_id',
    ELECTRIC_INSTALLATION_TYPE_ID: 'electric_installation_type_id',
    NETWORK_CONNECTION: 'network_connection',
    HAS_ME_ADAPTATION: "has_me_adaptation",
    EVC_ELECTRICAL_CHAIN_ID: 'evc_electrical_chain_id',
    NIF: 'nif',
    KNOW_NIF: 'know_nif',
    CONDO_ID: 'condo_id',
    MAX_CHARGING_POWER_ID: 'max_charging_power_id',
    MAX_CHARGING_POWER: 'max_charging_power',
    USER_CHARGING_POWER: 'user_charging_power',
    HAS_STORAGE_ROOM: 'has_storage_room',
    HAS_BILLING_INTEREST: 'has_billing_interest',

    //DISTANCE
    HOUSE_LEVEL: 'house_level',
    GARAGE_LEVEL: 'garage_level',
    VALUE: 'value',
    MEASUREMENT_TYPE_ID: 'measurement_type_id', //extra field TODO: verify whit backend when we need edit
    DISTANCE_RANGE_ID: 'distance_range_id', //extra field TODO: verify whit backend when we need edit
    MAIN_ELECTRICAL_PANEL_ID: 'main_electrical_panel_id',
    WIFI_CONNECTION: 'wifi_connection',
    INSTALLATION_SITE_ID: 'installation_site_id',
    NETWORK_CONNECTION_ID: 'network_connection_id',
    NETWORK_CONNECTION_TYPE_ID: 'network_connection_type_id',

    //PROPOSAL
    NAME: 'name',
    TAX_NUMBER: 'tax_number',
    EMAIL: 'email',
    PHONE_NUMBER: 'phone_number',
    ADDRESS: 'address',
    POSTAL_CODE: 'postal_code',
    LOCALITY: 'locality',
    PERSONAL_DATA_YIELD: 'personal_data_yield',

    //FACILITY
    //IS_CLIENT << DUPLICATE KEY
    INSTALLATION_CONNECTION_TYPE: 'installation_connection_type_id',
    INSTALLATION_CONNECTION_TYPE_ID: 'installation_connection_type_id',
    //ELECTRIC_INSTALLATION_TYPE_ID << DUPLICATE KEY
    CONTRACTED_POWER_VALUE: 'contracted_power_value',
    MAXIMUM_CONTRACTED_POWER: 'maximum_contracted_power',
    TOTAL_CONSUMPTION: 'total_consumption',
    POWER_START_DATE: 'power_start_date',
    POWER_END_DATE: 'power_end_date',
    SIZING_OPTION: 'sizing_option',
    PEAKPOWER_CURRENT: 'peakpower_current',
    MEANPOWER_CURRENT :'meanpower_current',

    //SWITCHBOARD
    QSC_LOCATION: 'qsc_location',
    DISTANCE_QSC_STAIRS: 'distance_qsc_stairs',
    SINGLE_PHASE_PERCENTAGE: 'single_phase_percentage',
    SMART_CHARGING_MODE: 'smart_charging_mode',

    //SIMULATION
    METER_SELECTED: 'meter_selected',

    //GARAGE
    LOCATION: 'location',
    PARKING_SPOTS: 'parking_spots',
    AREA: 'area',
    DISTANCE_PARKING_SPOTS_ID: 'distance_parking_spots_id',
    MAX_DISTANCE_PARKING_SPOTS: 'max_distance_parking_spots',
    MIN_DISTANCE_PARKING_SPOTS: 'min_distance_parking_spots',
    DISTANCE_PARKING_SPOT_QP_ID: 'distance_parking_spot_qp_id',
    MAX_DISTANCE_PARKING_SPOT_QP: 'max_distance_parking_spot_qp',
    MIN_DISTANCE_PARKING_SPOT_QP: 'min_distance_parking_spot_qp',
    DISTANCE_QP_TO_STAIRS: 'distance_qp_to_stairs',
}

export const FACILITY_SIZING_OPTIONS = {
    DIAGRAM_EREDES: '1',
    ACTUAL_CONSUMPTION: '2',
}

//#region QUESTIONS

export const ME_QUESTIONS_STEP_PROFILE = {
    [ME_INPUT_NAMES.IS_CLIENT]: {
        order: 1,
        name: ME_INPUT_NAMES.IS_CLIENT,
        label: `page.me.question.${ME_INPUT_NAMES.IS_CLIENT}`,
        disabled: false,
        answers: [{ label: "label.yes", value: true }, { label: "label.no", value: false }],
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.HAS_ELECTRIC_VEHICLE]: {
        order: 2,
        name: ME_INPUT_NAMES.HAS_ELECTRIC_VEHICLE,
        label: `page.me.question.${ME_INPUT_NAMES.HAS_ELECTRIC_VEHICLE}`,
        disabled: false,
        answers: [{ label: "label.yes", value: true }, { label: "label.noButThinkHave", value: false }],
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.BRAND_ID]: {
        order: 3,
        name: ME_INPUT_NAMES.BRAND_ID,
        label: `page.me.question.${ME_INPUT_NAMES.BRAND_ID}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.MODEL_ID]: {
        order: 4,
        name: ME_INPUT_NAMES.MODEL_ID,
        label: `page.me.question.${ME_INPUT_NAMES.MODEL_ID}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.DAILY_DISTANCE_KM]: {
        order: 5,
        name: ME_INPUT_NAMES.DAILY_DISTANCE_KM,
        label: `page.me.question.${ME_INPUT_NAMES.DAILY_DISTANCE_KM}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" },
        min: 10,
        max: 121,
        step: 1,
    },
};

export const ME_QUESTIONS_STEP_SPACE = {
    [ME_INPUT_NAMES.PARKING_TYPE_ID]: {
        order: 1,
        name: ME_INPUT_NAMES.PARKING_TYPE_ID,
        label: `page.me.question.${ME_INPUT_NAMES.PARKING_TYPE_ID}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.NETWORK_CONNECTION]: {
        order: 2,
        name: ME_INPUT_NAMES.NETWORK_CONNECTION,
        label: `page.me.question.${ME_INPUT_NAMES.NETWORK_CONNECTION}`,
        disabled: false,
        options: [{ label: intlMessages('label.yes'), value: true }, { label: intlMessages('label.no'), value: false }],
        visible: true,
        validation: { required: "yup.message.required" },
        isRow: true
    },
    [ME_INPUT_NAMES.BOX_CONNECTION_TYPE_ID]: {
        order: 4,
        name: ME_INPUT_NAMES.BOX_CONNECTION_TYPE_ID,
        label: `page.me.question.${ME_INPUT_NAMES.BOX_CONNECTION_TYPE_ID}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" },
        options: [
            {
                label: 'page.me.question.box_connection_type_id.LIGADO_FRACAO',
                value: ME_BOX_CONNECTION_TYPE_ID.FRACTION,
            },
            {
                label: 'page.me.question.box_connection_type_id.LIGADO_QSC',
                value: ME_BOX_CONNECTION_TYPE_ID.COMMON_SERVICES
            },
            {
                label: 'label.dontKnow',
                value: ME_BOX_CONNECTION_TYPE_ID.DONT_KNOW
            },
        ],
    },
    [ME_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: {
        order: 5,
        name: ME_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID,
        label: `page.me.question.${ME_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID}`,
        disabled: false,
        visible: true,
        answers: [
            { value: 1, description: "label.monophasic" },
            { value: 2, description: "label.threephasic" },
            { value: 3, description: "label.dontKnow" },
        ],
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.EVC_ELECTRICAL_CHAIN_ID]: {
        order: 5,
        name: ME_INPUT_NAMES.EVC_ELECTRICAL_CHAIN_ID,
        label: `page.me.question.${ME_INPUT_NAMES.EVC_ELECTRICAL_CHAIN_ID}`,
        disabled: false,
        visible: true,
        answers: [
            { value: 1, description: "label.yes" },
            { value: 2, description: "label.no" },
            { value: 3, description: "label.dontKnow" },
        ],
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.CONTRACTED_POWER_ID]: {
        order: 6,
        name: ME_INPUT_NAMES.CONTRACTED_POWER_ID,
        label: `page.me.question.${ME_INPUT_NAMES.CONTRACTED_POWER_ID}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.MAX_CHARGING_POWER_ID]: {
        order: 7,
        name: ME_INPUT_NAMES.MAX_CHARGING_POWER_ID,
        label: `page.me.question.${ME_INPUT_NAMES.MAX_CHARGING_POWER_ID}`,
        info: `page.me.question.${ME_INPUT_NAMES.MAX_CHARGING_POWER_ID}.info`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.MAX_CHARGING_POWER]: {
        order: 8,
        name: ME_INPUT_NAMES.MAX_CHARGING_POWER,
        label: `page.me.question.${ME_INPUT_NAMES.MAX_CHARGING_POWER}`,
        warning: `page.me.question.${ME_INPUT_NAMES.MAX_CHARGING_POWER}.warning`,
        disabled: false,
        visible: true,
        validation: validate([VALIDATION_RULES.REQUIRED, VALIDATION_RULES.MIN, VALIDATION_RULES.MAX], {min: 0, max: 22})
    },
    [ME_INPUT_NAMES.USER_CHARGING_POWER]: {
        order: 9,
        name: ME_INPUT_NAMES.USER_CHARGING_POWER,
        label: `page.me.question.${ME_INPUT_NAMES.USER_CHARGING_POWER}`,
        disabled: false,
        answers: [{ label: "label.yes", value: true }, { label: "label.no", value: false }],
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.HAS_ME_ADAPTATION]: {
        order: 10,
        name: ME_INPUT_NAMES.HAS_ME_ADAPTATION,
        label: `page.me.question.${ME_INPUT_NAMES.HAS_ME_ADAPTATION}`,
        disabled: false,
        answers: [{ label: "label.yes", value: true }, { label: "label.no", value: false }],
        visible: true,
        // validation: { required: "yup.message.required" },
        options: [
            {
                label: 'label.yes',
                value: true,
            },
            {
                label: 'label.no',
                value: false
            },
            {
                label: 'label.dontKnow',
                value: 3
            },
        ],
    },
    [ME_INPUT_NAMES.NIF]: {
        order: 7,
        name: ME_INPUT_NAMES.NIF,
        label: `page.me.question.${ME_INPUT_NAMES.NIF}`,
        disabled: false,
        visible: true,
        placeholder: `page.spv.question.${SPV_INPUT_NAMES.NIF}.placeholder`,
        validation: {
            required: "yup.message.required"
        }
    },
    [ME_INPUT_NAMES.CONDO_ID]: {
        order: 5,
        name: ME_INPUT_NAMES.CONDO_ID,
        label: `page.me.question.${ME_INPUT_NAMES.CONDO_ID}`,
        disabled: false,
        visible: true,
        answers: [],
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.HAS_STORAGE_ROOM]: {
        order: 4,
        name: ME_INPUT_NAMES.HAS_STORAGE_ROOM,
        label: `page.me.question.${ME_INPUT_NAMES.HAS_STORAGE_ROOM}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" },
        options: [
            {
                label: intlMessages('label.yes'),
                value: true,
            },
            {
                label: intlMessages('label.no'),
                value: false
            },
        ],
        isRow: true
    },
    [ME_INPUT_NAMES.KNOW_NIF]: {
        order: 4,
        name: ME_INPUT_NAMES.KNOW_NIF,
        label: `page.me.question.${ME_INPUT_NAMES.KNOW_NIF}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" },
        options: [
            {
                label: intlMessages('label.yes'),
                value: true,
            },
            {
                label: intlMessages('label.no'),
                value: false
            },
        ],
        isRow: true
    },
    [ME_INPUT_NAMES.HAS_BILLING_INTEREST]: {
        order: 4,
        name: ME_INPUT_NAMES.HAS_BILLING_INTEREST,
        label: `page.me.question.${ME_INPUT_NAMES.HAS_BILLING_INTEREST}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" },
        options: [
            {
                label: intlMessages('label.yes'),
                value: true,
            },
            {
                label: intlMessages('label.no'),
                value: false
            },
        ],
        isRow: true
    }
}

export const ME_QUESTIONS_STEP_DISTANCE = {
    [ME_INPUT_NAMES.HOUSE_LEVEL]: {
        order: 1,
        name: ME_INPUT_NAMES.HOUSE_LEVEL,
        label: `page.me.question.${ME_INPUT_NAMES.HOUSE_LEVEL}`,
        placeholder: `page.me.question.${ME_INPUT_NAMES.HOUSE_LEVEL}.placeholder`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.min'
            },
            max: {
                value: 25,
                message: 'yup.message.number.max'
            }
        }
    },
    [ME_INPUT_NAMES.GARAGE_LEVEL]: {
        order: 2,
        name: ME_INPUT_NAMES.GARAGE_LEVEL,
        label: `page.me.question.${ME_INPUT_NAMES.GARAGE_LEVEL}`,
        placeholder: `page.me.question.${ME_INPUT_NAMES.GARAGE_LEVEL}.placeholder`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            min: {
                value: -6,
                message: 'yup.message.number.min'
            },
            max: {
                value: 0,
                message: 'yup.message.number.max'
            }
        }
    },
    [ME_INPUT_NAMES.MEASUREMENT_TYPE_ID]: {
        order: 3,
        name: ME_INPUT_NAMES.MEASUREMENT_TYPE_ID,
        label: `page.me.question.${ME_INPUT_NAMES.MEASUREMENT_TYPE_ID}`,
        placeholder: `page.me.question.${ME_INPUT_NAMES.MEASUREMENT_TYPE_ID}.placeholder`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.VALUE]: {
        order: 4,
        name: ME_INPUT_NAMES.VALUE,
        label: `page.me.question.${ME_INPUT_NAMES.VALUE}`,
        placeholder: `page.me.question.${ME_INPUT_NAMES.VALUE}.placeholder`,
        disabled: false,
        visible: true,
        validation: validate([VALIDATION_RULES.REQUIRED, VALIDATION_RULES.MINNINCLUDED], {min: 0})
    },
    [ME_INPUT_NAMES.MAIN_ELECTRICAL_PANEL_ID]: {
        order: 5,
        name: ME_INPUT_NAMES.MAIN_ELECTRICAL_PANEL_ID,
        label: `page.me.question.${ME_INPUT_NAMES.MAIN_ELECTRICAL_PANEL_ID}`,
        tooltip: `page.me.question.${ME_INPUT_NAMES.MAIN_ELECTRICAL_PANEL_ID}.tooltip`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.NETWORK_CONNECTION_ID]: {
        order: 6,
        name: ME_INPUT_NAMES.NETWORK_CONNECTION_ID,
        label: `page.me.question.${ME_INPUT_NAMES.NETWORK_CONNECTION_ID}`,
        info: `page.me.question.${ME_INPUT_NAMES.NETWORK_CONNECTION_ID}.info`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.NETWORK_CONNECTION_TYPE_ID]: {
        order: 7,
        name: ME_INPUT_NAMES.NETWORK_CONNECTION_TYPE_ID,
        label: `page.me.question.${ME_INPUT_NAMES.NETWORK_CONNECTION_TYPE_ID}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.WIFI_CONNECTION]: {
        order: 8,
        name: ME_INPUT_NAMES.WIFI_CONNECTION,
        label: `page.me.question.${ME_INPUT_NAMES.WIFI_CONNECTION}`,
        disabled: false,
        answers: [{ label: "label.yes", value: true }, { label: "label.no", value: false }],
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.INSTALLATION_SITE_ID]: {
        order: 9,
        name: ME_INPUT_NAMES.INSTALLATION_SITE_ID,
        label: `page.me.question.${ME_INPUT_NAMES.INSTALLATION_SITE_ID}`,
        info: `page.me.question.${ME_INPUT_NAMES.INSTALLATION_SITE_ID}.info`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
}

export const ME_QUESTIONS_STEP_PROPOSAL = {
    [ME_INPUT_NAMES.NAME]: {
        order: 1,
        name: ME_INPUT_NAMES.NAME,
        placeholder: `label.name`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" },
    },
    [ME_INPUT_NAMES.TAX_NUMBER]: {
        order: 2,
        name: ME_INPUT_NAMES.TAX_NUMBER,
        placeholder: `label.nif`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            maxLength: {
                value: 9,
                message: 'yup.message.nifLength'
            },
            minLength: {
                value: 9,
                message: 'yup.message.nifLength'
            },
        },
    },
    [ME_INPUT_NAMES.EMAIL]: {
        order: 3,
        name: ME_INPUT_NAMES.EMAIL,
        placeholder: `label.email`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            pattern: {
                value: EMAIL_REGEXP,
                message: "yup.message.email",
            },
        },
    },
    [ME_INPUT_NAMES.PHONE_NUMBER]: {
        order: 4,
        name: ME_INPUT_NAMES.PHONE_NUMBER,
        placeholder: `label.phone`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
        },
    },
    [ME_INPUT_NAMES.ADDRESS]: {
        order: 5,
        name: ME_INPUT_NAMES.ADDRESS,
        placeholder: `label.address`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" },
    },
    [ME_INPUT_NAMES.POSTAL_CODE]: {
        order: 6,
        name: ME_INPUT_NAMES.POSTAL_CODE,
        placeholder: `label.postalCode`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
        },
    },
    [ME_INPUT_NAMES.LOCALITY]: {
        order: 7,
        name: ME_INPUT_NAMES.LOCALITY,
        placeholder: `label.locality`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
        },
    },
    [ME_INPUT_NAMES.PERSONAL_DATA_YIELD]: {
        order: 8,
        text: `page.me.final.${ME_INPUT_NAMES.PERSONAL_DATA_YIELD}`,
        name: ME_INPUT_NAMES.PERSONAL_DATA_YIELD,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" },
    },
};

export const ME_QUESTIONS_STEP_FACILITY = {
    [ME_INPUT_NAMES.IS_CLIENT]: {
        order: 1,
        name: ME_INPUT_NAMES.IS_CLIENT,
        label: `page.me.question.${ME_INPUT_NAMES.IS_CLIENT}.facility`,
        disabled: false,
        answers: [{ label: "label.yes", value: true }, { label: "label.no", value: false }],
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.INSTALLATION_CONNECTION_TYPE_ID]: {
        order: 2,
        name: ME_INPUT_NAMES.INSTALLATION_CONNECTION_TYPE_ID,
        label: `page.me.question.${ME_INPUT_NAMES.INSTALLATION_CONNECTION_TYPE_ID}`,
        disabled: false,
        visible: true,
        answers: null, //API
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: {
        order: 3,
        name: ME_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID,
        label: `page.me.question.${ME_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID}.facility`,
        disabled: false,
        visible: true,
        answers: [
            { value: 1, description: "label.monophasic" },
            { value: 2, description: "label.threephasic" },
            // { value: 3, description: "label.dontKnow" },
        ],
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.CONTRACTED_POWER_ID]: {
        order: 4,
        name: ME_INPUT_NAMES.CONTRACTED_POWER_ID,
        label: `page.me.question.${ME_INPUT_NAMES.CONTRACTED_POWER_ID}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [ME_INPUT_NAMES.CONTRACTED_POWER_VALUE]: {
        order: 4,
        name: ME_INPUT_NAMES.CONTRACTED_POWER_VALUE,
        label: `page.me.question.${ME_INPUT_NAMES.CONTRACTED_POWER_ID}`,
        placeholder: `yup.placeholder.general`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.min'
            },
            max: {
                value: 500,
                message: 'yup.message.number.max'
            },
            validate: (v) => {
                return ((parseFloat(v) >= 0) ? true : "yup.message.number.positive")
            },
        }
    },
    [ME_INPUT_NAMES.MAXIMUM_CONTRACTED_POWER]: {
        order: 5,
        name: ME_INPUT_NAMES.MAXIMUM_CONTRACTED_POWER,
        label: `page.me.question.${ME_INPUT_NAMES.MAXIMUM_CONTRACTED_POWER}`,
        placeholder: `yup.placeholder.general`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.min'
            },
            validate: (v) => {
                return ((parseFloat(v) >= 0) ? true : "yup.message.number.positive")
            },
        }
    },
    [ME_INPUT_NAMES.TOTAL_CONSUMPTION]: {
        order: 6,
        name: ME_INPUT_NAMES.TOTAL_CONSUMPTION,
        label: `page.me.question.${ME_INPUT_NAMES.TOTAL_CONSUMPTION}`,
        placeholder: `yup.placeholder.general`,
        disabled: false,
        visible: true,
        validation: {
            required: 'yup.message.required',
            min: {
                value: 0,
                message: 'yup.message.number.min'
            },
            // max: {
            //     value: 100,
            //     message: 'yup.message.number.max'
            // },
        }
    },
    [ME_INPUT_NAMES.SIZING_OPTION]: {
		order: 7,
		name: ME_INPUT_NAMES.SIZING_OPTION,
		label: `page.me.question.${ME_INPUT_NAMES.SIZING_OPTION}`,
        tooltip:'page.me.question.sizing_option.tooltip',
		disabled: false,
		visible: true,
		answers: [
			{ value: FACILITY_SIZING_OPTIONS.DIAGRAM_EREDES, label: 'page.me.question.sizing_option.label.1' },
			{ value: FACILITY_SIZING_OPTIONS.ACTUAL_CONSUMPTION, label: 'page.me.question.sizing_option.label.2' },
		],
		validation: { required: 'yup.message.required' },
	},
    [ME_INPUT_NAMES.POWER_START_DATE]: {
        order: 8,
        name: ME_INPUT_NAMES.POWER_START_DATE,
        label: `page.me.question.${ME_INPUT_NAMES.POWER_START_DATE}`,
        disabled: false,
        visible: true,
        validation: {
            required: 'yup.message.required'
        }
    },
    [ME_INPUT_NAMES.POWER_END_DATE]: {
        order: 9,
        name: ME_INPUT_NAMES.POWER_END_DATE,
        label: `page.me.question.${ME_INPUT_NAMES.POWER_END_DATE}`,
        disabled: false,
        visible: true,
        validation: {
            required: 'yup.message.required'
        }
    },
    [ME_INPUT_NAMES.PEAKPOWER_CURRENT]: {
        order: 10,
        name: ME_INPUT_NAMES.PEAKPOWER_CURRENT,
        label: `page.me.question.${ME_INPUT_NAMES.PEAKPOWER_CURRENT}`,
        placeholder: `yup.placeholder.general`,
        tooltip: `page.me.question.${ME_INPUT_NAMES.PEAKPOWER_CURRENT}.tooltip`,
        disabled: false,
        visible: true,
        validation: {
            required: 'yup.message.required'
        }
    },
    [ME_INPUT_NAMES.MEANPOWER_CURRENT]: {
        order: 11,
        name: ME_INPUT_NAMES.MEANPOWER_CURRENT,
        label: `page.me.question.${ME_INPUT_NAMES.MEANPOWER_CURRENT}`,
        placeholder: `yup.placeholder.general`,
        tooltip: `page.me.question.${ME_INPUT_NAMES.MEANPOWER_CURRENT}.tooltip`,
        disabled: false,
        visible: true,
        validation: {
            required: 'yup.message.required'
        },
    },
}

export const ME_QUESTIONS_STEP_SWITCHBOARD = {
    [ME_INPUT_NAMES.QSC_LOCATION]: {
        order: 5,
        name: ME_INPUT_NAMES.QSC_LOCATION,
        label: `page.me.question.${ME_INPUT_NAMES.QSC_LOCATION}`,
        placeholder: `yup.placeholder.general`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            min: {
                value: -2,
                message: 'yup.message.number.min'
            },
            max: {
                value: 2,
                message: 'yup.message.number.max'
            },
            setValueAs: v => parseInt(v)
        }
    },
    [ME_INPUT_NAMES.DISTANCE_QSC_STAIRS]: {
        order: 5,
        name: ME_INPUT_NAMES.DISTANCE_QSC_STAIRS,
        label: `page.me.question.${ME_INPUT_NAMES.DISTANCE_QSC_STAIRS}`,
        placeholder: `yup.placeholder.general`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.min'
            },
            max: {
                value: 100,
                message: 'yup.message.number.max'
            },
        }
    },
    [ME_INPUT_NAMES.SINGLE_PHASE_PERCENTAGE]: {
        order: 4,
        name: ME_INPUT_NAMES.SINGLE_PHASE_PERCENTAGE,
        label: `page.me.question.${ME_INPUT_NAMES.SINGLE_PHASE_PERCENTAGE}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" },
        min: 0,
        max: 100,
        step: 1,
    },
    [ME_INPUT_NAMES.SMART_CHARGING_MODE]: {
        order: 5,
        name: ME_INPUT_NAMES.SMART_CHARGING_MODE,
        label: `page.me.question.${ME_INPUT_NAMES.SMART_CHARGING_MODE}`,
        placeholder: `yup.placeholder.general`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
        },
        isRow: true,
        options: [
            {
                label: intlMessages('page.me.question.smart_charging_mode.option.static'),
                value: 'Static',
            },
            {
                label: intlMessages('page.me.question.smart_charging_mode.option.dynamic'),
                value: 'Dynamic'
            },
        ],
    },
}

export const ME_QUESTIONS_STEP_SIMULATION = {
    [ME_INPUT_NAMES.METER_SELECTED]: {
        order: 1,
        name: ME_INPUT_NAMES.METER_SELECTED,
        disabled: false,
        visible: true,
    },
};


export const ME_QUESTIONS_STEP_GARAGE = {
    [ME_INPUT_NAMES.LOCATION]: {
        order: 1,
        name: ME_INPUT_NAMES.LOCATION,
        label: `page.me.question.garage.${ME_INPUT_NAMES.LOCATION}`,
        placeholder: `page.me.question.garage.location.placeholder`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            min: {
                value: -20,
                message: 'yup.message.number.min'
            },
            max: {
                value: 20,
                message: 'yup.message.number.max'
            },
        }
    },
    [ME_INPUT_NAMES.PARKING_SPOTS]: {
        order: 2,
        name: ME_INPUT_NAMES.PARKING_SPOTS,
        label: `page.me.question.garage.${ME_INPUT_NAMES.PARKING_SPOTS}`,
        placeholder: `page.me.question.garage.parking_spots.placeholder`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.min'
            },
            max: {
                value: 200,
                message: 'yup.message.number.max'
            },
        }
    },
    [ME_INPUT_NAMES.AREA]: {
        order: 3,
        name: ME_INPUT_NAMES.AREA,
        label: `page.me.question.garage.${ME_INPUT_NAMES.AREA}`,
        placeholder: `yup.placeholder.general`,
        disabled: false,
        visible: true,
        validation: {
            // required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.min'
            }
        }
    },
    [ME_INPUT_NAMES.DISTANCE_PARKING_SPOTS_ID]: {
        order: 4,
        name: ME_INPUT_NAMES.DISTANCE_PARKING_SPOTS_ID,
        label: `page.me.question.garage.${ME_INPUT_NAMES.DISTANCE_PARKING_SPOTS_ID}`,
        placeholder: `label.selectOption`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required"
        }
    },
    [ME_INPUT_NAMES.DISTANCE_PARKING_SPOT_QP_ID]: {
        order: 4,
        name: ME_INPUT_NAMES.DISTANCE_PARKING_SPOT_QP_ID,
        label: `page.me.question.garage.${ME_INPUT_NAMES.DISTANCE_PARKING_SPOT_QP_ID}`,
        placeholder: `label.selectOption`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required"
        },
        answers: [],//TODO: respostavem da API
    },
    [ME_INPUT_NAMES.DISTANCE_QP_TO_STAIRS]: {
        order: 3,
        name: ME_INPUT_NAMES.DISTANCE_QP_TO_STAIRS,
        label: `page.me.question.garage.${ME_INPUT_NAMES.DISTANCE_QP_TO_STAIRS}`,
        placeholder: `yup.placeholder.general`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.min'
            },
            max: {
                value: 100,
                message: 'yup.message.number.max'
            }
        }
    },

    [ME_INPUT_NAMES.NETWORK_CONNECTION]: {
        order: 3,
        name: ME_INPUT_NAMES.NETWORK_CONNECTION,
        label: `page.me.question.garage.${ME_INPUT_NAMES.NETWORK_CONNECTION}`,
        placeholder: null,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
        },
        answers: [{ label: "label.yes", value: true }, { label: "label.no", value: false }],
    },
}

export const ME_GARAGE_INPUTS_DEFAULT = clone({
    floorId: 1,
    location: '',
    parking_spots: '',
    area: '',
    distance_parking_spots_id: '',
    max_distance_parking_spots: '',
    min_distance_parking_spots: '',
    distance_parking_spot_qp_id: '',
    max_distance_parking_spot_qp: '',
    min_distance_parking_spot_qp: '',
    distance_qp_to_stairs: '',
    network_connection: '',
})
//#endregion QUESTIONS

export const ME_BUSINESS_MODEL_IDS = {
    UP_FRONT: 4,
    INSTALLMENTS: 5
}