/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Actions - User
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */


import {
    REQUEST_GET_LOGIN,
    REQUEST_GET_LOGIN_FAIL,
    REQUEST_GET_LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_ADFS,
    SET_USER_AUTHENTICATED_FALSE,
    MODIFY_USER_DATA,
    REQUEST_GET_LOGIN_BY_SSO_FALSE,
    RESET_AUTH_IS_REQUESTING,
    RESET_AUTH_SHOW_PASSWORD
} from 'constants/user';

//Request Get Login - 
export const requestGetLogin = (payload) => {
    return {
        type: REQUEST_GET_LOGIN,
        payload
    };
};

//Request Get Login - Fail
export const requestGetLoginFail = () => {
    return {
        type: REQUEST_GET_LOGIN_FAIL
    };
};

//Request Get Login - Success
export const requestGetLoginSuccess = (payload) => {
    return {
        type: REQUEST_GET_LOGIN_SUCCESS,
        payload
    }
};

//Request Get Login - by SSO is False
export const requestGetLoginBySSOFalse = () => {
    return {
        type: REQUEST_GET_LOGIN_BY_SSO_FALSE,
    }
};

export const userSignOut = () => {
    return {
        type: LOGOUT_USER
    };
};

export const userSignOutADFS = () => {
    return {
        type: LOGOUT_USER_ADFS
    };
};
export const setUserAuthenticated = () => {
    return {
        type: SET_USER_AUTHENTICATED_FALSE
    };
};
export const resetAuthIsRequesting = () => {
    return {
        type: RESET_AUTH_IS_REQUESTING
    };
};
export const resetAuthShowPassowrd = () => {
    return {
        type: RESET_AUTH_SHOW_PASSWORD
    };
};

export const modifyUserData = (payload) => {
    return {
        type: MODIFY_USER_DATA,
        payload
    };
};
