import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { bool, func, number } from 'prop-types';
import { USER_GROUP_IDS } from 'constants/user';
import { resetAuthShowPassowrd } from 'actions/user';


const UserContext = createContext({
    company_id: number,
    user_group_id: number,
    user_type_id: number,
    active: bool,
    locale: 'en-GB',
    handleSetUser: func,
    hasAuditPermission: bool,
});
UserContext.displayName = 'efz-UserContext';


const UserProvider = ({ children }) => {

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state);
    const { isUserAuthenticated } = user.auth;
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        // userData must be cleaned after a logout session
        if (!isUserAuthenticated) {
            setUserData(null);
        }
    }, [isUserAuthenticated]);

    useEffect(() => {
        dispatch(resetAuthShowPassowrd())
    },[])//eslint-disable-line
    
    return (
        <UserContext.Provider value={
            {
                user,
                userData,
                setUserData,
                isUserDemo: user?.is_demo,
                has_aqs: user?.has_aqs,
                has_housing_with_panels: user?.has_housing_with_panels ?? false,
                isUserGroupStandard: (user?.user_group_id === USER_GROUP_IDS.STANDARD),//ONLY AC
                isUserGroupManager: (user?.user_group_id === USER_GROUP_IDS.MANAGER),//AC && LECs
                hasAuditPermission: user?.has_audit_files ?? false,
                locale: user?.locale ?? 'pt-PT'
            }
        }>
            {children}
        </UserContext.Provider>
    );
}

export { UserContext, UserProvider }
