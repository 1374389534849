/**
 * @author Daniel Bastos <daniel.bastos@caixamagica.pt>,
 *
 * @description Saga-redux User
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */
import jwt_decode from 'jwt-decode';
import { toast } from "react-toastify";
import { all, call, takeLatest, put } from 'redux-saga/effects';

//Constants
import {
    LOGOUT_USER,
    REQUEST_GET_LOGIN,
    AVAILABLE_LANGUAGES,
    AVAILABLE_COMPANIES,
    LOGOUT_USER_ADFS
} from 'constants/user';

import {
    NO_CONTENT,
    OK,
} from "constants/statusCodeHttp";
//Actions
import { requestGetLoginSuccess, requestGetLoginFail, requestGetLoginBySSOFalse, resetAuthIsRequesting, } from 'actions/user';
import { switchLanguage } from 'actions/settings';
import clone from 'fast-copy';
//API
import { auth, authV2, getLogoutADFS } from 'api/user';
import { intlMessages, isDefined } from 'services/util/auxiliaryUtils';
import { BASENAME_URL_LOGIN, BASENAME_URL_ADFS_TOKEN_EXPIRY, BASENAME_URL_AUTH_ADFS } from 'constants/settings';
import { history } from 'store';
import { goToAuthADFS } from 'services/user';

// Login Function
const _callRequestGetLogin = async (username) =>
    await authV2({ username })
        .then(authUser => {
            return authUser?.status !== OK ? authUser : authUser?.data;
        })
        .catch(error => {
            console.log("[SAGA] catch error _callRequestGetLogin ", error);
            return error;
        });

// Login Function
const _callRequestGetLoginWithPassword = async (username, password) =>
    await auth({ username, password })
        .then(authUser => {
            return authUser?.status !== OK ? authUser : authUser?.data;
        })
        .catch(error => {
            console.log("[SAGA] catch error _callRequestGetLogin ", error);
            return error;
        });

function* _handleRequestGetLogin({ payload }) {
    const { username, password, withPassword } = payload;
    try {
        const response = withPassword ? yield call(_callRequestGetLoginWithPassword, username, password) : yield call(_callRequestGetLogin, username);
        if (withPassword) {
            if (response?.status === OK && !!response?.data?.token) {
                yield _handleLoginCaseOK(response.data.token);
            } else {
                yield toast.error(response?.data?.message);
                yield put(requestGetLoginFail());
            }
        } else {
            if (response?.status === NO_CONTENT) {
                yield toast.error(intlMessages('label.userNotFound'));
                yield put(requestGetLoginFail());
            } else if (response?.status === OK) {
                if (response?.data?.login_by_sso) {
                    yield put(resetAuthIsRequesting());
                    yield history.push(BASENAME_URL_AUTH_ADFS);
                } else {
                    yield put(requestGetLoginBySSOFalse());
                }
            } else {
                yield toast.error(response?.data?.message);
                yield put(requestGetLoginFail());
            }
        }
    } catch (error) {
        console.log("[SAGA] catch error _handleRequestGetLogin ", error);
        yield toast.error(error);
        yield put(requestGetLoginFail());
    }
}

function* _handleLoginCaseOK(token) {
    yield localStorage.setItem('userToken', token);
    const jwtDecodeToken = jwt_decode(token);
    yield localStorage.setItem('uadfs', isDefined(jwtDecodeToken?.sessionIndex));
    let lngObj = AVAILABLE_LANGUAGES.find(obj => obj.locale === jwtDecodeToken.user.locale) ?? AVAILABLE_LANGUAGES[0];
    let companyName = AVAILABLE_COMPANIES.find(company => company.id === jwtDecodeToken.user.company_id)?.name;
    yield put(switchLanguage(lngObj));
    yield put(requestGetLoginSuccess(
        {
            ...jwtDecodeToken.user,
            companyName: companyName
        }
    ));
}



//LogOut
function* _handleLogOut() {
    try {
        yield localStorage.removeItem('userToken');
    } catch (error) {
        console.log("[SAGA] catch error _handleLogOut ", error);
        yield toast.error(error);
    }
}


function* _handleLogOutADFS() {
    try {

        const token = clone(localStorage.getItem('userToken'));
        const jwtDecodeToken = jwt_decode(token);
        let rsp = yield call(getLogoutADFS, token, jwtDecodeToken?.nameID, jwtDecodeToken?.sessionIndex);
        if (rsp?.status === OK) {
            goToAuthADFS(rsp?.data?.data?.uri ?? null);

        } else {
            history.push(BASENAME_URL_LOGIN);
        }

    } catch (error) {
        yield toast.error(error);
        history.push(BASENAME_URL_ADFS_TOKEN_EXPIRY);
    }
}



export default function* rootSaga() {
    yield all([
        takeLatest(LOGOUT_USER_ADFS, _handleLogOutADFS),
        takeLatest(LOGOUT_USER, _handleLogOut),
        takeLatest(REQUEST_GET_LOGIN, _handleRequestGetLogin),
    ]);
}