/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @component LoginForm
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */

import React, { memo } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { Link } from "react-router-dom";
import { Button } from '@save2compete/efz-design-system';
import IntlMessages from "components/util/IntlMessages";
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ErrorMessage';
import { OutlinedInput } from '@mui/material';
import { intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';
import { BASENAME_URL } from 'constants/settings';
import { resetAuthShowPassowrd } from 'actions/user';
import { useDispatch } from 'react-redux';

/**
 * 
 * @param {*} param0 
 */
const LoginForm = ({
    isRequesting,
    handleOnSubmit,
    showPassword
}) => {

    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            username: '',
            password: ''
        },
    });

    const watchUsername = useWatch({control, name: 'username'})
    const watchPassword = useWatch({control, name: 'password'})

    const onSubmit = payload => {
        payload.withPassword = showPassword
        handleOnSubmit(payload)
    };

    const handleChange = () => {
        reset()
        dispatch(resetAuthShowPassowrd())
    }

    return (
        <div>
            <form autoComplete={'true'} onSubmit={handleSubmit(onSubmit)} className="login-form">
                <div className="col-12 col-lg-10 col-xl-8 position-relative">
                    {/* username */}
                    <Controller
                        
                        name="username"
                        control={control}
                        rules={{ required: "yup.message.required" }}
                        render={({ field }) => (
                            <OutlinedInput
                                {...field}
                                className={`w-100 ${errors?.username ? '' : 'mb-3'}`}
                                disabled={isRequesting || showPassword}
                                error={errors?.username}
                                placeholder={intlMessages('label.username')}
                            />
                        )}
                    />
                    {errors?.username && <span className="d-block mb-3 w-100"><ErrorMessage error={errors.username} /></span>}
                    { 
                        showPassword && <div className='change-button' onClick={handleChange}>
                            <span><IntlMessages id="label.change"/></span>
                        </div>
                    }
                    {/* password */}
                    {
                        showPassword && <Controller
                            name="password"
                            control={control}
                            rules={{ required: "yup.message.required" }}
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    type={'password'}
                                    disabled={isRequesting}
                                    error={errors?.password}
                                    placeholder={intlMessages('label.password')}
                                    className="w-100"
                                />
                            )}
                        />
                    }
                    {showPassword && errors?.password && <ErrorMessage error={errors.password} />}
                </div>
                {/*actions*/}
                <div className={`d-flex align-items-center justify-content-center col-12 col-lg-10 col-xl-8 ${showPassword ? 'mt-4' : 'mt-0'}`}>
                    <Button
                        variant="primary"
                        size='bg'
                        type="submit"
                        disabled={isRequesting || !isFieldDefined(watchUsername) || (showPassword && !isFieldDefined(watchPassword))}
                        loading={isRequesting}
                        //className="w-100"
                        dataTestId='login'
                    >
                        <IntlMessages id={'label.signin'} />
                    </Button>
                </div>
                {/*reset password*/}
                {
                    showPassword && <div className="d-flex align-items-center justify-content-center mt-4">
                        <h4>
                            <Link className={isRequesting ? 'disabled-cursor' : ''} to={`${BASENAME_URL}reset-password`}>
                                <IntlMessages id={'page.login.forgetPassword'} />
                            </Link>
                        </h4>
                    </div>
                }
            </form>
        </div>
    )
}


LoginForm.propTypes = {
    isRequesting: PropTypes.bool.isRequired,
    handleOnSubmit: PropTypes.func.isRequired,
};

export default memo(LoginForm);