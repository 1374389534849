import edpTheme from "pages/themes/edpTheme";

// * DEFAULT OBJECTS * \\
export const INITIAL_USER = {
    id: null,
    name: null,
    username: null,
    profile_id: null,
    user_type_id: null,
    user_group_id: null,
    language: null,
    auth: {
        isRequesting: false,
        isUserAuthenticated: false,
        showPassword: false
    },
    locale: "en-GB",
    currency: {
        description: "Euro",
        code: "EUR",
        symbol: "€",
        sub_unit_description: "cent",
        sub_unit_symbol: "c"
    }
};

export const AVAILABLE_LOCALES = {
    ptPT: 'pt-PT',
    enGB: 'en-GB',
};

export const AVAILABLE_LANGUAGES = [
    {
        languageId: 'portuguese',
        locale: AVAILABLE_LOCALES.ptPT,
        localePickers: 'pt',
        name: 'label.portuguese',
        icon: 'pt',
        flagIcon: `assets/images/icons/svg/flags/pt.svg`,
    },
    {
        languageId: 'english',
        locale: AVAILABLE_LOCALES.enGB,
        localePickers: 'en-gb',
        name: 'label.english',
        icon: 'gb',
        flagIcon: `assets/images/icons/svg/flags/gb.svg`,
    },

];

export const EXT_FIELD = {
    PHONE: '(+351)',
    CPE: 'PT0002',
}

// * CONSTANTS * \\
export const USERS_TYPE_ID = {
    MANAGER: 1,//PLATFORM
    SUPERVISOR: 2,//PLATFORM
    AGENT: 3,//PLATFORM
    CHANNEL: 4,//PLATFORM
    CLIENT: 5,//PLATFORM
};



export const USER_COMPANY_IDS = {
    EDP_PT: 1,
    EDP_ES: 2,
    CEZ: 3,
    CLP: 4,
    ESB: 5,
    EDP_IT: 6,
    DEMO: 7,
    EDP_PL: 8,
    EDP_BR: 9
}

export const AVAILABLE_COMPANIES = [
    {
        id: USER_COMPANY_IDS.EDP_PT,
        name: 'EDP',
        theme: edpTheme,
        usePoweredBy: true
    },
];

export const USER_GROUP_IDS = {
    STANDARD: 1,//Auto Consumo (SELF_CONSUMPTION)
    MANAGER: 2,//SELF_CONSUMPTION & LECs
}


// * ACTION TYPES * \\
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_ADFS = 'LOGOUT_USER_ADFS';
export const REQUEST_GET_LOGIN = 'REQUEST_GET_LOGIN';
export const RESET_AUTH_IS_REQUESTING = 'RESET_AUTH_IS_REQUESTING';
export const RESET_AUTH_SHOW_PASSWORD = 'RESET_AUTH_SHOW_PASSWORD';
export const REQUEST_GET_LOGIN_FAIL = 'REQUEST_GET_LOGIN_FAIL';
export const REQUEST_GET_LOGIN_SUCCESS = 'REQUEST_GET_LOGIN_SUCCESS';
export const REQUEST_GET_LOGIN_BY_SSO_TRUE = 'REQUEST_GET_LOGIN_BY_SSO_TRUE';
export const REQUEST_GET_LOGIN_BY_SSO_FALSE = 'REQUEST_GET_LOGIN_BY_SSO_FALSE';
export const SET_USER_AUTHENTICATED_FALSE = 'SET_USER_AUTHENTICATED_FALSE';
export const MODIFY_USER_DATA = 'MODIFY_USER_DATA';