import { ENVIRONMENTS_FE } from "constants/settings";

const ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : ENVIRONMENTS_FE.DEV;


/**
 * getDomain
 * 
 * @param {*} env (DEV, TST, PRD)
 */
const getDomain = (env) => {
    const locationHost = document.location.host;

    //1. only env DEV (FE & BE)
    if (env === ENVIRONMENTS_FE.DEV) return process.env.REACT_APP_API_URL_DEV

    //3. in FE env development 
    if (process.env.NODE_ENV === 'development') {
        return (env === ENVIRONMENTS_FE.TST) ?
            process.env.REACT_APP_API_URL_TST :
            process.env.REACT_APP_API_URL_PRD
    }

    //All on servers
    return locationHost;
};


/**
 * getBaseURL
 *
 * @return {*} 
 */
export function getBaseURL(isWhitoutApiURL = false) {
    return (ENV === ENVIRONMENTS_FE.DEV ? 'http://' : 'https://') +
        getDomain(ENV) + (isWhitoutApiURL ? '' : '/api/')
}

/**
 * getBrand
 */
export function getBrand() {
    return 'effizency';
}


export function isEnvProduction() {
    return (process.env.REACT_APP_ENV === ENVIRONMENTS_FE.PRD)
}

export function isEnvDevelopment() {
    return ([ENVIRONMENTS_FE.DEV, ENVIRONMENTS_FE.TST].includes(process.env.REACT_APP_ENV))
}

export function isDevelopment() {
    return (process.env.NODE_ENV === 'development')
};