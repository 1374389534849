export const FORM_INPUTS_TYPE = {
    SELECT: 'SELECT',
    SELECT_TEXT_FIELD: 'SELECT_TEXT_FIELD',
    DOUBLE_SELECT: 'DOUBLE_SELECT',
    RADIO_WITH_LABEL: 'RADIO_WITH_LABEL',
    SELECTABLE_ELEMENT: 'SELECTABLE_ELEMENT',
    MULTIPLE_SELECTABLE_ELEMENT_WITH_CHECK: 'MULTIPLE_SELECTABLE_ELEMENT_WITH_CHECK',
    MULTIPLE_SELECTABLE_ELEMENT_WITH_SWITCH: 'MULTIPLE_SELECTABLE_ELEMENT_WITH_SWITCH',
    NUMBER: 'NUMBER',
    QUANTITY_SELECTOR: 'QUANTITY_SELECTOR',
    SELECT_WITH_INPUT: 'SELECT_WITH_INPUT',
    CHECKBOX: 'CHECKBOX',
    PHONE_NUMBER_PT: 'PHONE_NUMBER_PT',
    MOBILE_DATE_PICKER: 'MOBILE_DATE_PICKER'
}