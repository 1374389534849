import React from 'react';
import * as Sentry from "@sentry/react";
import { Provider } from 'react-redux';
import Router from 'app/routes/Router';
import configureStore, { history } from './store';
import App from './pages/App';
import { PersistGate } from "redux-persist/integration/react";

export const { store, persistor } = configureStore();

const MainApp = () =>
    <Sentry.ErrorBoundary fallback={"An error has occured"}>
        <Router history={history}>
            <Provider store={store}>
                <PersistGate persistor={persistor} >
                    <App />
                </PersistGate>
            </Provider>
        </Router>
    </Sentry.ErrorBoundary>;

export default MainApp;