import pt_PT from 'lngProvider/locales/pt_PT';
import en_GB from 'lngProvider/locales/en_GB';
import clone from 'fast-copy';
import { AVAILABLE_LOCALES } from "constants/user";
import { StatusCodes } from 'http-status-codes';

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const TELEPHONE_REGEXP = /^\+?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/g;
export const POSTALCODE_REGEXP = /^([0-9]{4})-([0-9]{3})$/g;
export const FLOAT_REGEXP = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
export const isNumber = (value) => typeof value === 'number';
export const isValidEmail = (email) => EMAIL_REGEXP.test(email);
export const isValidTelephone = (phone) => TELEPHONE_REGEXP.test(phone);
// export const isValidPostalCode = (postalCode) => POSTALCODE_REGEXP.test(postalCode);
export const PASSWORD_CHECK = {
    PASSWORD_HAS_LOWERCASE_REGEXP: /(?=.*[a-z])/, // Matches if password has at least 1 lower case character
    PASSWORD_HAS_UPPERCASE_REGEXP: /(?=.*[A-Z])/, // Matches if password has at least 1 upper case character
    PASSWORD_HAS_DIGIT_REGEXP: /(?=(.*[\d]){1,})/, // Matches if password has at least 2 digits
    PASSWORD_HAS_SPECIAL_CHARACTER: /(?=(.*[-+_!@#$%^&*.,?]){1,})/, // Matches if password has at least 2 special characters
    PASSWORD_IS_VALID: /(?=.*[a-z])(?=.*[A-Z])(?=(.*\d){2,})(?=(.*[\W]){2,})(?!.*\s).{8,}/, // All the above checks in one and minimum 9 characters
}
export const getBoolean = (value) => (typeof value === "boolean" ? value : (value === 'true'));
const getLocaleCodeByLS = () => (!!localStorage.getItem('LOCALE_CODE') ? localStorage.getItem('LOCALE_CODE') : 'en');//default EN
export const isExistWord = (string, word) => string.search(word) !== -1;
export const isDefined = (input) => (input !== undefined && input !== null);
export const isFieldDefined = (field) => isDefined(field) && field !== '';
export const parseBoolean = (input) => {
    if (getBoolean(input)) return getBoolean(input);
    return ![undefined, null, NaN, false, 0].includes(input) && !["false", "0", ""].includes(input?.toLowerCase()) ? !!input : false
};
export const justNumbers = (string) => (string.replace(/\D/g, ''));
export const isValidPhone = (string) => {
    let extPT = /((\+351))/;
    return extPT.test(string) ? (justNumbers(string).length === 12) : (justNumbers(string).length === 9)
}; // Compares the string receives and check if number has 12 digits (351 included) or 9 digits (without 351)

/**
 * removeEmptyInObj
 *
 * @param {} obj
 */
export const removeEmptyInObj = function (obj) {
    for (let key in obj) if (!isFieldDefined(obj?.[key])) delete obj[key];
    return clone(obj);
};

export const generateRandomString = () =>
    Math.random().toString(36).substring(2, 4) + Math.random().toString(36).substring(2, 4).toUpperCase();



/**
 * trimAll removes all whitespace
 *
 * @param {*} str
 */
export const trimAll = (str) => str.replace(/\s/g, '');

/**
 * isEmpty array
 * @param array
 * @returns {boolean}
 */
export const isEmptyArray = (array) => array.length === 0;

// we have to do some additional check
export const isEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

/**
 * intlMessages
 *
 * @param id - string (keyword)
 * @param values - {*}
 * @returns {*}
 */
export function intlMessages(id, values = null) {
    let translation = getTranslations()[id];

    //replacements
    if (!!values) {
        for (var prop in values) {
            if (Object.prototype.hasOwnProperty.call(values, prop))
                translation = translation.replace(`{${prop}}`, values[prop]);
        }
    }
    return !!translation ? translation : `PLEASE TRANSLATE THIS (${id}) KEYWORD`;
}

/**
 * getTranslations
 *
 * @param language
 * @returns {*}
 */
const getTranslations = () => {
    let language = getLocaleCodeByLS();
    switch (language ?? 'en') {
        case AVAILABLE_LOCALES.ptPT:
            return pt_PT;
        case AVAILABLE_LOCALES.enGB:
            return en_GB;
        default:
            return en_GB;
    }
};

export const getLetterAvatar = (type, lettersNumber) => type.substr(-type.length, lettersNumber).toUpperCase();

// * Functions * \\
//Get Product Icon
export const getProductIcon = (tp_id) => {
    return require(`assets/images/products/icons/product-id-${tp_id}.svg`);
};

//Get Product Banner
export const getProductBanner = (tp_id) => {
    return require(`assets/images/products/banners/product-id-${tp_id}.png`);
};


/**
 * parseNumberWithToFixed
 * 
 * @param {*} value 
 * @param {*} decimalPlaces 
 */
export const parseNumberWithToFixed = (value, decimalPlaces) => (
    isDefined(decimalPlaces) ? parseFloat(parseFloat(value).toFixed(decimalPlaces)) : value
)

// Loading while try to send Email or update State
export const setLoadingByType = (array, id, type) => {
    return array.map((item) => {
        if (item.id === id) {
            switch (type) {
                case 'email':
                    item.sendingEmailLoading = !item.sendingEmailLoading;
                    break;

                case 'state':
                    item.updateStateLoading = !item.updateStateLoading;
                    break;

                default:
                    break;
            }
        }
        return item;
    });
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const scrollToRef = (scrollTo) => (scrollTo.current?.scrollIntoView({ behavior: "smooth" }));

export const scrollToTopContainer = (id) => {
    let top = document.getElementById(id);

    top.scrollTop = 0;
}

export const scrollToTopByClass = (className) => {
    document.getElementsByClassName(className)[0].scrollIntoView()
}

//Function to return the data in the correct formar 
//This functions is used to construct the file name
export const parseData = (dt) => {
    let day = dt.getDate();
    let month = dt.getMonth() + 1;
    let year = dt.getFullYear();

    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    return year + "-" + month + "-" + day;
}

export const createDownloadFile = (fileName, data) => {
    let url = URL.createObjectURL(new Blob([data]));
    let a = document.createElement('a');

    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
}

/**
 * createFormDataWithImageBlob
 * 
 * @param {*} imageBlob 
 * @param {*} inputName 
 * @param {*} fileName 
 * @returns  formData
 */
export const createFormDataWithImageBlob = (
    imageBlob,
    inputName = '',
    fileName = 'image'
) => {
    let filename = `${fileName}-${new Date().getTime()}.png`;
    let file = new File([imageBlob], filename, { type: 'image/png' });
    let formData = new FormData();
    formData.append(inputName, file);
    return formData;
}

export const dateFormatted = date => {
    let date_formatted = date;

    if (!!date_formatted) {
        date_formatted = new Date(date);
        date_formatted.setHours(0, 0, 0);
        date_formatted = date_formatted.getTime();
    }

    return date_formatted;
}

/**
 * getNextID
 *
 * @param _array
 * @param id
 * @param findBy
 * @returns {number}
 */
export const getNextID = (_array, id, findBy) => {
    if (_array.length === 0)
        return 1;

    let next = (id === 0) ? 1 : id;

    for (id; id <= _array.length; id++) {
        let value = _array.find(function (x) { return (x[findBy] === next) });//eslint-disable-line
        if (value === undefined) { break; }
        next++;
    }
    return next;
};

export function validateNumber(value, decimalPlaces = 0, canBeNegative = false) {
    if (!canBeNegative && value < 0) return 0; // do not allow negative values in such cases
    const multiplier = Math.pow(10, decimalPlaces)
    return Math.round(parseFloat(value) * multiplier) / multiplier;
}

export function onlyNumber(string) {
    return string.replace(/[^0-9]/g, '')

}


/**
 * getNextID
 *
 * @param key 
 * Key must be in style of x => x.key_name
 */
export const removeDuplicateObjectByKey = (data, key) => {
    return [
        ...new Map(
        data?.map(x => [key(x), x])
        ).values()
    ]
}

export const isAllStatusOK = (rsps) => {
    let errors = [];

    let responses = rsps.filter(el => (isFieldDefined(el)));
    responses.forEach(el => {
        if (el.status !== StatusCodes.OK) {
            errors.push({
                url: el.request?.responseURL,
                response: el?.request?.response
            })
        }
    })

    return errors
};

export const isTabSelected = (tabIndex, selectIndex) => (tabIndex === selectIndex);