import { AVAILABLE_LOCALES } from 'constants/user';
import ptMessages from '../locales/pt_PT.json';

const ptLang = {
  messages: {
    ...ptMessages
  },
  locale: AVAILABLE_LOCALES.ptPT,
};
export default ptLang;
