import { useState } from 'react';
import { sha256 } from 'js-sha256';
import { toast } from "react-toastify";
import { getResetPassword, putRedefinePassword } from 'api/user';
import IntlMessages from "components/util/IntlMessages";
import { OK, BAD_REQUEST } from 'constants/statusCodeHttp';


const useRecoveryPassword = () => {

    const [isRequesting, setIsRequesting] = useState(false);
    const [isRequestingRedefinePwd, setIsRequestingRedefinePwd] = useState(false);
    const [msgResetPassword, setMsgResetPassword] = useState(null);

    const fetchResetPassword = async (email) => {

        setMsgResetPassword(null);

        setIsRequesting(true);
        const response = await getResetPassword(email);

        if ([OK, BAD_REQUEST].includes(response?.status)) {
            setMsgResetPassword('page.resetPwd.confirmYourEmail');
        }

        setIsRequesting(false);
    }

    const fetchRedefinePassword = async (userSignOut, userID, body, onCloseDialog) => {

        setIsRequestingRedefinePwd(true);
        const response = await putRedefinePassword(
            userID,
            { password_: sha256(body.password) }
        );

        if (response?.status === OK) {
            localStorage.removeItem('userToken');
            toast.success(<IntlMessages id='label.changeWasSuccessful' />);
            userSignOut();
            onCloseDialog();
        }

        setIsRequestingRedefinePwd(false);
    }


    return {
        fetchResetPassword,
        isRequesting,
        isRequestingRedefinePwd,
        fetchRedefinePassword,
        msgResetPassword
    }
}

export default useRecoveryPassword;
