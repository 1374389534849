import { memo } from 'react';

import LanguageItem from './LanguageItem';
import { AVAILABLE_LANGUAGES } from "constants/user";

const LanguageSwitcher = ({ switchLanguage, handleRequestClose, activeLanguage }) => {
    return (
        <div className="language-list">
            <ul className="list-unstyled px-0 m-0">
                {AVAILABLE_LANGUAGES.map((language, index) =>
                    <LanguageItem
                        key={index}
                        language={language}
                        handleRequestClose={handleRequestClose}
                        switchLanguage={switchLanguage}
                        isActive={language.icon === activeLanguage}
                    />
                )}
            </ul>
        </div>
    )
};

export default memo(LanguageSwitcher);

