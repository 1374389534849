import { intlMessages } from "services/util/auxiliaryUtils";

// * DEFAULT OBJECTS * \\

// FIXME: mensagem não pode ser traduzida na constante...
//MESSAGE responses 
export const UNEXPECTED_ERROR = intlMessages('server.error.unexpectedError');
export const NETWORK_ERROR = intlMessages('server.error.networkError'); 
export const INCORRECT_DATA  = intlMessages('server.error.incorrectData');
export const MSG_BAD_GATEWAY  = intlMessages('server.error.502');
export const SESSION_EXPIRED  =  intlMessages('server.error.sessionExpired');
export const MSG_SERVER_ERROR = intlMessages('server.error.500');

// * ACTION TYPES * \\
//STATUS Successful responses
export const OK = 200;
export const CREATE = 201;
export const ACCEPTED = 202;
export const NO_CONTENT = 204;

//STATUS Client error responses
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;

//STATUS Server error responses
export const INTERNAL_SERVER_ERROR = 500;
export const BAD_GATEWAY = 502;
export const SERVICE_UNAVAILABLE = 503;
export const TOAST_SESSION_EXPIRED_ID = 'session_expired_notification';
export const TOAST_MSG_SERVER_ERROR_ID = 'msg_server_error_notification';
export const TOAST_MSG_BAD_REQUEST_ID = 'msg_bad_request_notification';
export const TOAST_CHECK_STATUS_ERROR_ID = 'check_status_notification';
