/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Selector Settings
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */
import { createSelector } from 'reselect';

export const getLocale = createSelector(state => state.locale, locale => locale);
export const getSettingsLocale = createSelector(state => state.locale.locale, locale => locale);
export const getIsDarkTheme = createSelector(state => state.darkTheme, darkTheme => darkTheme);