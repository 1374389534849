import PropTypes from 'prop-types';

// Icons
import { ReactComponent as CloseCookieIcon } from 'assets/images/icons/svg/closeCookie.svg';

// Material UI
import { IconButton } from '@mui/material';

const CookieHeader = ({ 
    title,
    close
}) => {

    return (
        <div className='cookie-consent-header'>
            <IconButton className='close-cookie' onClick={() => close()} size="large">
                <CloseCookieIcon/>
            </IconButton>
            <span>
                {title}
            </span>
        </div>
    )
}

CookieHeader.propTypes = {
    title: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
}

export default CookieHeader;