/**
 * @author Daniel Bastos <daniel.bastos@caixamagica.pt>,
 *
 * @description Component async loader - Works with React Lazy. Avoids loading chunk X failed when redeploying app.
 * This error is caused by creating new chunks without the user reloading the page.
 * Solution: force page reload is the error is detected.
 *
 * @version 20201204
 * @version 20201002
 * @since 20201002 Initial release
 *
 */

export default function lazyLoader(lazyComponent, isCacheBuster) {

    if (isCacheBuster) {
        console.log('Reloading due to new version available');
        window.location.reload(true);
        return;
    }

    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                if (error.toString().indexOf('ChunkLoadError') > -1) {
                    console.log('[ChunkLoadError] Reloading due to error');
                    window.location.reload(true);
                    return;
                }
                if (error.toString().indexOf("SyntaxError: Unexpected token '<'") > -1) {
                    console.log('Reloading due to error');
                    window.location.reload(true);
                    return;
                }
                else {
                    reject(error);
                    return;
                }
            });
    });

}