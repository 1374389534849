import IntlMessages from 'components/util/IntlMessages';
import CookieConsent from 'components/CookieConsent';
import { useState } from 'react';

// Icons 
import { ReactComponent as CookieIcon } from 'assets/images/icons/svg/cookie.svg';

const CookieBanner = () => {

    const [policyTypeToShow, setPolicyTypeToShow] = useState(null);

    const showPolicyHandler = (type = null) => {
        if (!!type) {
            setPolicyTypeToShow(type);
        } else {
            setPolicyTypeToShow(null);
        }
    }

    return (
        <div className='cookie-banner-content'>
            <div className='cookie-banner-title'>
                <CookieIcon className='me-2' />
                <span>
                    <IntlMessages id='label.cookies.title' />
                </span>
            </div>
            <div className='cookie-banner-text'>
                <IntlMessages id='label.cookies.content' whitespaceEnd />
                <span className='link' onClick={() => showPolicyHandler('privacy-policy')} ><IntlMessages id='label.here' /></span>.
            </div>
            {!!policyTypeToShow && <CookieConsent type={policyTypeToShow} close={showPolicyHandler} showPolicyHandler={showPolicyHandler} />}
        </div>
    )
}

export default CookieBanner;