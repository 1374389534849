/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Page to Restricted responsável por construir o routeamento do container e sincroniza o componente em questão.
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */

import { lazy, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Suspense } from 'react';
import lazyLoader from 'services/lazyLoader';

// actions
import {
    userSignOut,
    setUserAuthenticated
} from 'actions/user';

// Components
import Footer from "./shared/Footer";
import Loading from 'components/Loading';
import RedefinePasswordDialog from 'components/RedefinePasswordDialog';
import { history } from 'store';
import { getIsUserAuthenticated, getUserID, isChangedPassword } from 'selectors/user';
import { getBoolean, isFieldDefined } from 'services/util/auxiliaryUtils';
import { toast } from 'react-toastify';
import { SESSION_EXPIRED } from 'constants/statusCodeHttp';
import { BASENAME_URL_ADFS_TOKEN_EXPIRY, BASENAME_URL_LOGIN } from 'constants/settings';
import Header from './shared/Header';

// Routes
const Error404 = lazy(() => lazyLoader(() => import("components/Error404")));
const Processes = lazy(() => lazyLoader(() => import('app/routes/Processes')));
const Users = lazy(() => lazyLoader(() => import('app/routes/Users')));
const Opportunities = lazy(() => lazyLoader(() => import("app/routes/Opportunities")));
const Product = lazy(() => lazyLoader(() => import("app/routes/Products")));
const User = lazy(() => lazyLoader(() => import("app/routes/User")));
const CookiePolicy = lazy(() => lazyLoader(() => import("app/routes/CookiePolicy")));


const Restricted = ({
    isChangedPassword,
    isUserAuthenticated,
    userID,
    userSignOut,
}) => {

    const location = useLocation();

    const prevLocation = useRef(location.pathname);

    //#region Cancel requests on page leave

    history.listen((location) => {
        if (prevLocation.current !== location.pathname) {
            window.stop();
            prevLocation.current = location.pathname;
        }
    })
    //#endregion

    let hasToken = isFieldDefined(localStorage?.getItem('userToken'));
    let isUADFS = isFieldDefined(localStorage.getItem('uadfs')) ? getBoolean(localStorage.getItem('uadfs')) : false;

    useEffect(() => { // Must be in a use effect to avoid updating redux's state while rendering this component. Use effect only runs after renders.
        if (!hasToken && isUserAuthenticated) {//istokenvalido
            toast.warning(SESSION_EXPIRED);
            userSignOut();
        }
    }, [isUserAuthenticated, hasToken]); // eslint-disable-line

    if (!(isUserAuthenticated && hasToken)) {
        return <Navigate to={{ pathname: isUADFS ? BASENAME_URL_ADFS_TOKEN_EXPIRY : BASENAME_URL_LOGIN }} />
    }

    return (
        <div className={`app-container`}>
            {
                isUserAuthenticated && <div className="app-main-container">
                    <Header />
                    <main id='main-container' className="app-main-content-wrapper">
                        {/* <context> */}
                        <div className="app-main-content">
                            <Suspense fallback={<Loading useTopLoading={true} size={30} />}>
                                <Routes>
                                    <Route path={`processes`} element={<Processes />} />
                                    <Route path={`users`} element={<Users />} />
                                    <Route path={`opportunities/:id`} element={<Product />} />
                                    <Route path={`opportunities/*`} element={<Opportunities />} />
                                    <Route path={`user`} element={<User />} />
                                    <Route path={`cookie-policies`} element={<CookiePolicy />} />
                                    <Route path='*' element={<Error404 />} />
                                </Routes>
                            </Suspense>
                        </div>
                        <Footer isUserAuthenticated={isUserAuthenticated} />
                        {
                            !isChangedPassword &&
                            <RedefinePasswordDialog
                                userID={userID}
                                userSignOut={userSignOut}
                            />
                        }
                    </main>
                </div>
            }
        </div>
    )
}

const mapStateToProps = ({ user }) => {
    return {
        isUserAuthenticated: getIsUserAuthenticated(user),
        isChangedPassword: isChangedPassword(user),
        userID: getUserID(user),
    }
};

export default connect(mapStateToProps, {
    userSignOut,
    setUserAuthenticated,
})(Restricted);
