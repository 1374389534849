/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Componente que tem como funcionalidade apresentar apresentar uma alerta de espera ao utilizador
 *
 * @version 20210226
 * @since 20210226 Initial release
 *
 */

import { memo } from 'react';
import PropTypes from "prop-types";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IntlMessages from 'components/util/IntlMessages';
import Loading from 'components/Loading';

const LoadingDialog = ({
    isOpen,
    title,
    subtitle
}) => {


    const handleCloseDialog = (_, reason) => {
        if (['backdropClick', 'escapeKeyDown'].includes(reason)) return false;
    };

    return <Dialog
        onClose={(_, reason) => handleCloseDialog(_, reason)}
        open={isOpen}
        PaperProps={{ className: "loading-dialog" }}
    >
        <DialogTitle className="text-center">
            <IntlMessages id={title} />
        </DialogTitle>
        <DialogContent className="container">
            <Loading size={64} className="mb-3" />
            <DialogContentText className={`text-center`}>
                {!!subtitle && <IntlMessages id={subtitle} />}
            </DialogContentText>
        </DialogContent>
    </Dialog>
};


LoadingDialog.defaultProps = {
    title: 'label.loading',
    subtitle: 'label.pleaseWait',
}

// PropTypes
LoadingDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
};

export default memo(LoadingDialog);