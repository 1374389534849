/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Reducers - Setting
 *
 * @version 20200915
 * @since 20200915 Initial release
 *
 */
import clone from 'fast-copy';
import {
    DARK_THEME,
    SWITCH_LANGUAGE,
    THEME_COLOR,
    INITIAL_SETTINGS,
} from 'constants/settings';



const initialSettings = clone(INITIAL_SETTINGS);

const settings = (state = initialSettings, action) => {

    switch (action.type) {
        case THEME_COLOR:
            return {
                ...state,
                darkTheme: false,
            };
        case DARK_THEME:
            return {
                ...state,
                darkTheme: !state.darkTheme
            };
        case SWITCH_LANGUAGE:
            const { locale } = action.payload;
            localStorage.setItem('LOCALE_CODE', locale);
            return {
                ...state,
                locale: action.payload,
            };
        default:
            return state;
    }
};

export default settings;