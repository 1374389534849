/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 * @description redux sagas - ALL
 *
 * @see redux-sagas docs: https://redux-saga.js.org/ || https://redux-saga.js.org/docs/basics/DeclarativeEffects.html
 *
 * @since 20200918 Initial release
 *
 */


import {all, spawn, call} from 'redux-saga/effects';
import userSagas from './user';


export default function* rootSaga() {
    const sagas = [
        userSagas,
    ];

    yield all(sagas.map(saga =>
        spawn(function* () {
            while (true) {
                try {
                    yield call(saga);
                    break;
                } catch (e) {
                    console.log(e);
                }
            }
        })
    ));
}
